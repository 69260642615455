import React, { useEffect, useState } from 'react';
import './Pivot.css';
import QueryList from './widgets/QueryList';
import PivotTable from './widgets/PivotTable';
import permissions from '../../services/Permissions';
import { getLogsByQuery } from '../../api/host/datadog';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from '@atlaskit/spinner';

const Pivot = () => {
  const [currentReport, setCurrentReport] = useState('');
  const [permission, setPermissions] = useState({});
  const [pivotData, setPivotData] = useState([]);
  const [counter, setCounter] = useState([]);

  function saveQueries(value) {
    setCurrentReport(value);
  }

  useEffect(() => {
    (async () => {
      setPivotData([]);
      setCounter([]);
      let arr = [];
      await paginated_fetch(null, arr, currentReport);
    })();
  }, [currentReport]);

  async function paginated_fetch(cursor, arr, query) {
    return await getLogsByQuery(cursor, query)
      .then(async (response) => {
        arr = [...arr, ...response.data];
        setCounter(arr);
        if (response.data.length < 2000) {
          return setPivotData(arr);
        }
        if (response.links.next && cursor !== response.links.next) {
          await paginated_fetch(response.meta.page.after, arr);
        }
      })
      .catch((e) => console.error(e));
  }

  useEffect(() => {
    permissions.checkPermissions('pivots').then((permission) => {
      setPermissions(permission);
    });
  }, []);

  return (
    <>
      {permission.write ? (
        <div className="query-list-container">
          <QueryList updatePivot={saveQueries} />
        </div>
      ) : (
        <></>
      )}
      <div className="pivot-table-container">
        {pivotData.length ? (
          <PivotTable data={pivotData} />
        ) : (
          <>
            <div className="spinner-container">
              <div className="center-spinner-block">
                <div>
                  <Spinner size={230} />
                  <div
                    style={{
                      fontSize: 14,
                      marginTop: -138,
                      marginLeft: 50,
                    }}>
                    <strong>{counter.length}</strong> Logs Founded
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Pivot;
