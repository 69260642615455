import React, { useEffect, useState } from 'react';
import { getAddons } from '../../../api/host/addons';
import Select from '@atlaskit/select';
import { selectorStyle } from '../selectorStyles';
import { getAvailableNotifications } from '../../../api/host/notifications';
import { setProduct } from '../../../redux/actions/dash-board-actions';
import { connect } from 'react-redux';

const ProductSelector = (props) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [products, setProducts] = useState({});

  useEffect(() => {
    getAddons().then((response) => {
      if (response.length) {
        setProducts(response.map((product) => ({ value: product, label: product })));
        if (!props.selectedValues?.product?.value) {
          props.setSelectedProduct({ value: response[0], label: response[0] });
          setSelectedProduct({ value: response[0], label: response[0] });
        } else {
          props.setSelectedProduct(props.selectedValues.product);
          setSelectedProduct(props.selectedValues.product);
        }
      }
    });
  }, []);

  useEffect(() => {
    handleSetProduct(props.product);
  }, [props.product]);

  const handleSetProduct = (product) => {
    setSelectedProduct(product);
    props.dispatchProduct(product);
    props.setSelectedProduct(product);
  };

  return (
    <>
      {products.length ? (
        <Select
          className="select-container"
          inputId="grouped-options-example"
          onChange={(product) => handleSetProduct(product)}
          styles={selectorStyle}
          isDisabled={props.isDisabled}
          value={selectedProduct}
          options={products}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    product: state.dashboard.product,
  };
};

const mapDispatchToProps = {
  dispatchProduct: setProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelector);
