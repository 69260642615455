import {
  SET_CHECKED_USER,
  SET_PRODUCT,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_USER,
  SET_USER_JSON,
  SET_JIRA_JSON,
  SET_UPDATE_JSON_EDITOR,
  SET_QUERY_USER,
} from '../types/dash-board-types';

const url = new URL(document.URL).searchParams;
const product = url.get('addon');

const initialState = {
  selectedAccount: null,
  selectedUser: null,
  checkedUser: [],
  userJson: null,
  jirasJson: null,
  setUpdate: false,
  product: { value: product, label: product },
  setQueryUser: [],
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccount: action.payload,
        checkedUser: [],
        selectedUser: null,
      };
    }
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.payload,
      };
    }
    case SET_CHECKED_USER: {
      return { ...state, checkedUser: action.payload };
    }

    case SET_PRODUCT: {
      return {
        ...state,
        product: action.payload,
        checkedUser: [],
      };
    }
    case SET_USER_JSON: {
      return {
        ...state,
        userJson: action.payload,
      };
    }
    case SET_JIRA_JSON: {
      return {
        ...state,
        jirasJson: action.payload,
      };
    }
    case SET_UPDATE_JSON_EDITOR: {
      return {
        ...state,
        setUpdate: action.payload,
      };
    }
    case SET_QUERY_USER: {
      return {
        ...state,
        setQueryUser: action.payload,
      };
    }
    default:
      return state;
  }
};
