import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import HtmlViewer from './HtmlViewer';
import LastViewTable from './LastViewTable';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '../Notification.css';
import { createLog } from '../../../api/host/internal-logs';
import { deleted_notification, viewed_notification_message } from '../../../utlils/logsMessages';
import { toast } from 'react-toastify';
import ModalSubmit from '../../../utlils/ModalSubmit';
import moment from 'moment';
import permissions from '../../../services/Permissions';
import styled from '@emotion/styled';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import {
  NotificationsQuery,
  useDeleteNotificationMutation,
  useNotificationsByAddonKeyQuery,
} from '../../../graphql/generated';
import { GridReadyEvent, SideBarDef } from 'ag-grid-community';
import { getShortUrl } from '../../../utlils';

const NotificationTable = (props: {
  permission: {
    write: boolean;
    read: boolean;
  };
  setId: (value: { id: string; toProduct: string }) => void;
  product: string;
  isUpdated: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsUpdated: (updated: boolean) => void;
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dataForPreview, setDataForPreview] = useState('');
  const [dataForLastView, setDataForLastView] = useState<
    {
      jiraText: string;
      userAccountText: string;
      date: string;
    }[]
  >();
  const [title, setTitle] = useState('');
  const [gridOptions, setGridOptions] = useState<GridReadyEvent>();
  const [permission, setPermissions] = useState({
    write: false,
    read: false,
  });
  const closeModal = useCallback(() => setIsOpen(false), []);
  const [deleteNotification] = useDeleteNotificationMutation();
  const notificationsQuery = useNotificationsByAddonKeyQuery({
    variables: {
      addonKey: props.product,
    },
  });
  const notifications = notificationsQuery.data?.notificationsByAddonKey || [];
  const onGridReady = (event: GridReadyEvent) => {
    setGridOptions(event);
    event.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (props.product.length && gridOptions && notifications) {
      void notificationsQuery.refetch();
      props.setIsUpdated(false);
    }
  }, [props.product, gridOptions, props.isUpdated]);

  useEffect(() => {
    if (props.product.length && gridOptions && notifications) {
      gridOptions.api.setRowData(notifications);
    }
  }, [notifications, props.product, gridOptions]);

  useEffect(() => {
    void permissions.checkPermissions('notifications').then((permission) => {
      setPermissions(permission);
    });
  }, []);

  const Publication = (cell: {
    value: NotificationsQuery['notifications'][0]['publicationDate'];
  }) => {
    const date = new Date().toISOString();
    const TEN_MINUTES = 10 * 60 * 1000;
    if (cell.value > TEN_MINUTES) {
      console.log('ok');
    }
    let color;
    let title;
    if (Number(new Date(cell.value)) - Number(new Date(date)) > 0) {
      title = 'Unpublished';
      color = '#CCC500';
    } else {
      title = 'Published';
      color = 'green';
    }
    return (
      <p
        style={{
          color: color,
        }}>
        {' '}
        {title}{' '}
      </p>
    );
  };

  const PublicationDate = (cell: {
    value: NotificationsQuery['notifications'][0]['publicationDate'];
  }) => {
    const dateTime = moment(cell.value);

    const date = dateTime.format('MMM D, YYYY');
    const time = dateTime.format('h:mm A');

    return (
      <p>
        {date}, {time}
      </p>
    );
  };

  const InlineDialog = styled(TooltipPrimitive)`
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #333;
    max-width: 235px;
    padding: 8px 12px;
  `;

  const dateComparator = (initialDate: string, dateToCompare: string) => {
    return Number(new Date(initialDate)) - Number(new Date(dateToCompare));
  };

  const user = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    const users = cell.data.destination.toUser?.map((user) => user.userId).join(',\n') || '';

    return (
      <div>
        {users.length > 0 ? (
          <Tooltip component={InlineDialog} content={users}>
            <p key={users}> {users.length < 30 ? users : users.substring(0, 20) + '...'} </p>
          </Tooltip>
        ) : (
          <p key={users}> All </p>
        )}
      </div>
    );
  };

  const account = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    const accounts =
      cell.data.destination.toAccount?.map((account) => getShortUrl(account.baseUrl)).join(',\n') ||
      '';
    return (
      <div>
        {accounts.length > 0 ? (
          <Tooltip component={InlineDialog} content={accounts}>
            <p key={accounts}>
              {' '}
              {accounts.length < 30 ? accounts : accounts.substring(0, 20) + '...'}{' '}
            </p>
          </Tooltip>
        ) : (
          <p key={accounts}> All </p>
        )}
      </div>
    );
  };

  const AccountsId = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    const accounts =
      cell.data.destination.toAccount?.map((account) => account.id).join(',\n') || '';
    return (
      <div>
        {accounts.length > 0 ? (
          <Tooltip component={InlineDialog} content={accounts}>
            <p key={accounts}>
              {' '}
              {accounts.length < 30 ? accounts : accounts.substring(0, 20) + '...'}{' '}
            </p>
          </Tooltip>
        ) : (
          <p key={accounts}> All </p>
        )}
      </div>
    );
  };

  const lastViewedBy = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    return (
      <>
        <div className="notification-button-last-view">
          <Button
            isDisabled={!cell.data.watchedBy?.length}
            className="button-last-view"
            onClick={() => {
              const LastView = cell.data.watchedBy?.map((value) => {
                return {
                  date: moment('').format('YYYY-MM-DD HH:mm'), // Todo add date field
                  jiraText: value.accountId.clientKey,
                  userAccountText: value.userId,
                };
              });
              setDataForLastView(LastView);
              setIsOpen(true);
            }}>
            Show
          </Button>
        </div>
      </>
    );
  };
  const Preview = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    return (
      <>
        <div className="notification-button">
          <Button
            isDisabled={!cell.data.message.length}
            className="table-preview-button"
            onClick={() => {
              setTitle(cell.data.title);
              setDataForPreview(cell.data.message);
              setIsOpen(true);
              void createLog(viewed_notification_message, 200);
            }}>
            Preview
          </Button>
        </div>
      </>
    );
  };
  const actionsRender = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    const onDelete = () => {
      deleteNotification({ variables: { id: cell.data.id || '' } })
        .then(() => {
          void createLog(deleted_notification, 200);
          toast.success('Notification deleted successfully!');
          props.setIsUpdated(true);
        })
        .catch(() => {
          toast.error('You didnt have permisions to remove Notification!');
        });
    };

    return (
      <>
        <div className="notification-button">
          <Button
            isDisabled={!cell.data.message.length}
            className="table-preview-button"
            onClick={() => {
              setTitle(cell.data.title);
              setDataForPreview(cell.data.message);
              setIsOpen(true);
              void createLog(viewed_notification_message, 200);
            }}>
            Preview
          </Button>
          <Button
            className="table-preview-button"
            onClick={() => {
              props.setId({ id: cell.data.id || '', toProduct: cell.data.addonKey });
              props.setIsOpen(true);
            }}>
            Edit
          </Button>
          <ModalSubmit
            appearance={'default'}
            buttonName={'Delete'}
            submit={onDelete}
            className={'table-preview-button'}
            text={'Delete Notification?'}
            icon={'default'}
            isDisabled={false}
          />
        </div>
      </>
    );
  };

  const onRenderToAll = (cell: { data: NotificationsQuery['notifications'][0] }) => {
    return <span> {cell.data.destination.toAll ? 'Yes' : 'No'}</span>;
  };

  const onRemoveHtml = (cell: { value: string }) => {
    return (
      <>
        <span> {cell.value.replace(/<\/?[^>]+(>|$)/g, '')} </span>
      </>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          enableRangeSelection={true}
          pagination={true}
          ref={gridRef}
          paginationPageSize={30}
          frameworkComponents={{
            Publication,
            PublicationDate,
            edit: actionsRender,
            preview: Preview,
            user,
            account,
            onRemoveHtml,
            LastViewedBy: lastViewedBy,
            onRenderToAll,
            AccountsId,
          }}
          animateRows={true}
          modules={AllCommunityModules}
          suppres
          defaultColDef={{
            flex: 1,
            minWidth: 230,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            sortable: true,
            editable: false,
            filter: true,
            resizable: true,
          }}
          sideBar={sideBar}
          onGridReady={onGridReady}
          rowData={null}>
          <AgGridColumn
            headerName="Publications"
            suppressColumnsToolPanel={true}
            field="publicationDate"
            cellRenderer="Publication"
          />
          <AgGridColumn
            headerName="Publication Date"
            suppressColumnsToolPanel={true}
            comparator={dateComparator}
            cellRenderer="PublicationDate"
            field="publicationDate"
          />
          <AgGridColumn headerName="To All" cellRenderer="onRenderToAll" initialHide={true} />
          <AgGridColumn headerName="To Users" autoHeight={true} cellRenderer="user" />
          <AgGridColumn
            headerName="To Accounts ID"
            initialHide={true}
            autoHeight={true}
            cellRenderer="AccountsId"
          />
          <AgGridColumn headerName="To Accounts" autoHeight={true} cellRenderer="account" />
          <AgGridColumn headerName="Title" field="title" cellRenderer="onRemoveHtml" />
          <AgGridColumn
            headerName="Last View"
            field="whoIsRead"
            cellRenderer="LastViewedBy"
            autoHeight={true}
            sortable={false}
            filter={false}
          />
          {!permission.write ? (
            <AgGridColumn
              filter={false}
              sortable={false}
              headerName="Preview"
              cellRenderer="preview"
            />
          ) : undefined}
          {permission.write ? (
            <AgGridColumn
              filter={false}
              sortable={false}
              headerName="Actions"
              cellRenderer="edit"
            />
          ) : undefined}
        </AgGridReact>
      </div>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal} width={dataForPreview ? 440 : 800}>
            <ModalHeader>
              {dataForPreview ? (
                <h4 className="notification-title-preview">
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      fontFamily:
                        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
                    }}>
                    {title.replace(/<\/?[^>]+(>|$)/g, '')}
                  </span>
                </h4>
              ) : (
                <h4> Last View </h4>
              )}
            </ModalHeader>
            <ModalBody>
              {dataForPreview ? (
                <HtmlViewer template={dataForPreview} />
              ) : (
                <LastViewTable data={dataForLastView} />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="primary"
                onClick={() => {
                  closeModal();
                  setDataForPreview('');
                }}
                autoFocus>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};
export default NotificationTable;

const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      minWidth: 180,
      maxWidth: 400,
      width: 250,
    },
  ],
  position: 'left',
  defaultToolPanel: '',
};
