import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import '../../../App.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { getShortUrl, viewLogs } from '../../../utlils';
import { getLogsByAccount } from '../../../api/host/datadog';
import { Accordion } from 'react-bootstrap';
import { EmptyState } from '../../../images';
import Spinner from '@atlaskit/spinner';
import CustomEmptyState from '../../helpers/CustomEmptyState';

const Chart = (props) => {
  const [logs, setLogs] = useState([]);
  const [selectedLogs, setSelectedLogs] = useState(null);

  useEffect(() => {
    setLogs([]);
    setSelectedLogs(null);
    if (props.selectedAccount?.installed?.baseUrl) {
      let atlassianName = getShortUrl(props.selectedAccount.installed.baseUrl);
      getLogsByAccount(atlassianName, props.product.value)
        .then((response) => {
          setLogs(response);
          if (response.length === 0) {
            setSelectedLogs(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.selectedAccount?.installed?.baseUrl, props.product.value]);

  const buildChart = () => {
    const dates = getDatesArray()
      .map((dates) => {
        return {
          day: dates.day,
          status: {
            ok: false,
            warn: false,
            error: false,
          },
        };
      })
      .filter((filteredDate) => filteredDate.day !== undefined);

    const times = getDatesArray()
      .map((times) => {
        return {
          day: selectedLogs,
          time: times.time,
          status: {
            ok: false,
            warn: false,
            error: false,
          },
        };
      })
      .filter((filteredTime) => filteredTime.time !== undefined);

    let DateByHours = [...times, ...getChartLogs(logs).flat()]
      .filter((filteredDate) => filteredDate.day === selectedLogs)
      .map((timeByStatus) => {
        return {
          time: timeByStatus.time,
          status: timeByStatus.status,
        };
      });

    const generateSeries = (
      selectedLogs !== null ? DateByHours : [...dates, ...getChartLogs(logs).flat()]
    ).reduce((previousLogs, currentLogs) => {
      if (previousLogs[currentLogs.day || currentLogs.time]) {
        previousLogs[currentLogs.day || currentLogs.time].status.ok += currentLogs.status.ok;
        previousLogs[currentLogs.day || currentLogs.time].status.error += currentLogs.status.error;
        previousLogs[currentLogs.day || currentLogs.time].status.warn += currentLogs.status.warn;
      } else {
        previousLogs[currentLogs.day || currentLogs.time] = currentLogs;
      }
      return previousLogs;
    }, {});

    const generatedCategory = [];
    Object.values(generateSeries).map((category) => {
      if (!generatedCategory.includes(category.day || category.time)) {
        return generatedCategory.push(category.day || category.time);
      }
      return null;
    });

    const ok = Object.values(generateSeries).map((ok) => ok.status.ok);
    const error = Object.values(generateSeries).map((error) => error.status.error);
    const warn = Object.values(generateSeries).map((warn) => warn.status.warn);

    return { ok, error, warn, generatedCategory };
  };

  const chart = buildChart();

  const options = {
    chart: {
      type: 'column',
      backgroundColor: '#FFFFFF',
      events: {
        click: function () {
          setSelectedLogs(null);
        },
      },
    },
    title: {
      text: selectedLogs ? `Chart by ${selectedLogs} by hours` : 'Chart by 30 day of Datadog',
      style: {
        fontWeight: 'bold',
        color: 'black',
      },
    },
    xAxis: {
      categories: chart.generatedCategory,
      labels: {
        style: {
          color: 'write',
        },
      },
    },
    yAxis: {
      min: 0,
      labels: {
        style: {
          color: 'black',
        },
      },
      title: {
        text: null,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      },
    },
    tooltip: {
      headerFormat: ' {point.x}<br/> ',
      pointFormat: selectedLogs
        ? '{series.name}: {point.y}<br/>Total: {point.stackTotal} <br/> <i>Click to move to the datadog</i><br/> <i>Click on background to move back</i> <br/> '
        : '{series.name}: {point.y}<br/>Total: {point.stackTotal}<br/> <i>Click for detailed view</i> <br/>',
    },
    legend: {
      align: 'right',
      x: -30,
      verticalAlign: 'top',
      y: 25,
      floating: true,
      backgroundColor: '#ffffff',
      borderColor: 'white',
      borderWidth: 1,
      shadow: false,
      itemStyle: {
        color: '#000000',
        listStyle: 'none',
        listStyleImage: 'none',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              if (selectedLogs) {
                const createDateURL = getChartLogs(logs)
                  .flat()
                  .reduce((previousLogs, currentLogs) => {
                    if (currentLogs.day === selectedLogs && currentLogs.time === this.category) {
                      if (previousLogs[currentLogs.day]) {
                        previousLogs[currentLogs.day].day = currentLogs.day === this.category;
                      } else {
                        previousLogs[currentLogs.day] = currentLogs;
                      }
                    }
                    return previousLogs;
                  }, {});
                let message;
                let atlassianName = getShortUrl(props.selectedAccount?.installed?.baseUrl);
                let date = Object.values(createDateURL)[0].date;
                if (this.series.name === 'ok') {
                  message = 'info';
                } else {
                  message = this.series.name;
                }
                viewLogs(message, atlassianName, date, props.product.value);
              } else {
                setSelectedLogs(this.category);
              }
            },
          },
        },
      },
    },
    series: [
      {
        name: 'ok',
        data: chart.ok,
        color: '#00875A',
      },
      {
        name: 'error',
        data: chart.error,
        color: '#DE350B',
      },
      {
        name: 'warn',
        data: chart.warn,
        color: '#FFC400',
      },
    ],
  };

  return (
    <>
      <Accordion defaultActiveKey="0" className="dashboard">
        <Accordion.Item eventKey="0">
          <Accordion.Header> Logs By {getShortUrl(props.selectedAccount?.installed?.baseUrl)} </Accordion.Header>
          <Accordion.Body>
            {props.selectedAccount ? (
              <>
                {logs.length !== 0 || selectedLogs === false ? (
                  <>
                    {logs.length !== 0 ? (
                      <div
                        style={{
                          marginBottom: '30px',
                        }}>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                      </div>
                    ) : (
                      <div style={{ marginBottom: '30px' }}>
                        <div className="empty-state"> {EmptyState} </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="empty-state">
                    <Spinner size={50} />
                  </div>
                )}
              </>
            ) : (
              <CustomEmptyState message="No account selected" />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

function getChartLogs(logs) {
  return logs.map((data) => {
    return data.computes.c0.map((e) => {
      return {
        day: moment(e.time).format('DD-MMM'),
        time: moment(e.time).format('HH:00'),
        date: moment(e.time).format('YYYY-MM-DD HH:00:00'),
        status: {
          ok: data.by.status.includes('info') ? e.value : false,
          warn: data.by.status.includes('warn') ? e.value : false,
          error: data.by.status.includes('error') ? e.value : false,
        },
      };
    });
    // data: data.data.length !== 0 ? data.data[0].computes.c0 : false
  });
}

function getDatesArray() {
  const res = [];
  let date = moment(new Date()).subtract(30, 'day');
  while (date < moment(new Date())) {
    res.push({ day: moment(date).format('DD-MMM') });
    date = date.add(1, 'day');
  }

  let time = moment(new Date()).subtract(1, 'day');
  while (time < moment(new Date())) {
    res.push({ time: moment(time).format('HH:00') });
    time = time.add(1, 'hour');
  }
  return res;
}

function mapStateToProps(state) {
  return {
    product: state.dashboard.product,
  };
}

export default connect(mapStateToProps)(Chart);
