import axios from '../axiosClient';
import moment from 'moment';

export function createLog(message: string, status: number, params = {}) {
  return axios
    .post(
      '/api/logs',
      {
        message: message,
        status: status,
        params: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getInternalLogs(page: string) {
  return axios
    .get(`/api/logs?page=${page}`)
    .then((response) => {
      return response.data.map((logs) => {
        return {
          date: moment(logs.date).format('MMM DD, YYYY HH:mm:ss'),
          log_msg: logs.log_msg,
          params: logs.params,
          status: logs.status,
          user_email: logs.user_email,
          user_role: logs.user_role,
          _id: logs._id,
        };
      });
    })
    .catch(() => {
      void createLog('can`t load system logs', 400);
    });
}

export function getAccountFromLogs(account: string, info: string) {
  return axios
    .get(`/api/logs/account?account=${account}&info=${info}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      void createLog('can`t load system logs', 400);
    });
}
export function getUserFromLogs(clientKey: string) {
  return axios
    .get(`/api/logs/user?key=${clientKey}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      void createLog('can`t load system logs', 400);
    });
}
