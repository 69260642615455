import React, { useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import JsonTable from './JsonTable';
import { getAccountFromLogs, getUserFromLogs } from '../../../api/host/internal-logs';
import Difference from './Difference';

const JsonModal = (props) => {
  const [data, setData] = useState(null);
  const [showDiff, setDiff] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [diffText, setDiffText] = useState('');

  useEffect(() => {
    if (props.tabs === 2) {
      getAccountFromLogs(props.user.account, props.user.info.accountId)
        .then((response) => setData(response))
        .catch(() => {
          setData([]);
        });
    }

    if (props.tabs === 1) {
      getUserFromLogs(props.account.installed.clientKey)
        .then((response) => setData(response))
        .catch(() => {
          setData([]);
        });
    }
  }, [props.tabs]);

  const showDifference = (value) => {
    setDiff(value);
  };
  const setDifference = (value) => {
    setDiffText(value);
  };

  return (
    <div>
      <Modal width={showDiff ? '100%' : 'medium'} onClose={props.closeModal}>
        <ModalHeader>
          <div>
            <ModalTitle>History</ModalTitle>
          </div>
          {showDiff && (
            <div>
              <Button
                style={{ marginRight: '5px' }}
                onClick={() => showDifference(false)}
                spacing="compact">
                Back
              </Button>
              <Button onClick={() => setExpanded(!expanded)} appearance="primary" spacing="compact">
                Expand
              </Button>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          {showDiff ? (
            <div>
              <Difference diffText={diffText} expanded={expanded} />
            </div>
          ) : (
            <>
              <JsonTable data={data} showDiff={showDifference} setDiff={setDifference} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.closeModal} autoFocus>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default JsonModal;
