import React, { useEffect, useRef, useState } from 'react';
import './UserManager.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import permissions from '../../services/Permissions';
import { changeUser, getUsers, removeUser } from '../../api/host/users';
import { changed_role, user_deleted } from '../../utlils/logsMessages';
import { createLog } from '../../api/host/internal-logs';
import { toast } from 'react-toastify';
import { DeleteIcon, EditIcon } from '../../images';
import { connect } from 'react-redux';
import { setComponent } from '../../redux/actions/main-actions';
import { history } from '../../history';
import ModalSubmit from '../../utlils/ModalSubmit';

const UserManager = (props) => {
  const gridRef = useRef();
  const [users, setUsers] = useState([]);
  const [permission, setPermissions] = useState({});

  useEffect(() => {
    permissions.checkPermissions('user-manager').then((permission) => {
      setPermissions(permission);
    });
    getUsers().then((response) => {
      setUsers(response);
    });
  }, []);

  const onCellValueChanged = (event) => {
    return changeUser(event.colDef.field, event.value, event.data.email).then(() => {
      if (props.user.email === event.data.email) {
        props.dispatchComponent('/');
        window.location = '/';
      }
      createLog(changed_role, 200);
      toast.success('Successfully changed user role');
    });
  };

  const deleteUser = (userId) => {
    removeUser(userId).then((response) => {
      setUsers(response);
      createLog(user_deleted, 200);
      toast.success('Successfully deleted user');
    });
  };

  const MyCellRenderer = (cell) => {
    return (
      <p>
        {cell.value} {EditIcon}{' '}
      </p>
    );
  };

  const RenderRemove = (cell) => {
    return cell.value !== props.user.id ? (
      <ModalSubmit
        icon={DeleteIcon}
        submit={() => {
          deleteUser(cell.value);
        }}
        text={'Are you sure you want to delete this user?'}
        appearance={'subtle'}
      />
    ) : (
      <></>
    );
  };

  const formatDate = (cell) => {
    return moment(cell.value).format('MMM DD, YYYY HH:mm:ss');
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current.api.sizeColumnsToFit();
  };

  return (
    <div style={{ width: '100%', height: '100%', paddingBottom: '5px' }}>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          enableRangeSelection={true}
          pagination={true}
          defaultColDef={{
            flex: 1,
            editable: true,
            sortable: true,
            minWidth: 230,
          }}
          rowData={users}
          onCellValueChanged={onCellValueChanged}
          autoGroupColumnDef={{
            headerName: 'Group',
            minWidth: 250,
            field: 'model',
            rowGroupIndex: 1,
          }}
          frameworkComponents={{
            format_date: formatDate,
            MyCellRenderer: MyCellRenderer,
            RenderRemove: RenderRemove,
          }}
          onGridReady={onGridReady}>
          <AgGridColumn headerName="Name" field="givenName" sort={'asc'} editable={false} />
          <AgGridColumn headerName="Surname" field="familyName" editable={false} />
          <AgGridColumn headerName="Email" field="email" editable={false} />
          <AgGridColumn
            headerName="Last Time Logged"
            field="log_time"
            cellRenderer="format_date"
            editable={false}
          />
          <AgGridColumn
            headerName="Role"
            field="role"
            cellEditor="agRichSelectCellEditor"
            cellEditorButton={true}
            cellEditorPopup={true}
            singleClickEdit={true}
            cellRenderer={'MyCellRenderer'}
            cellEditorParams={{
              values: [
                'admin',
                'manager',
                'developer',
                'support',
                'tech-support',
                'marketer',
                'qa',
              ],
              cellHeight: 40,
            }}
            editable={permission.write}
          />
          <AgGridColumn headerName="IP" field="ip" editable={false} />
          <AgGridColumn
            headerName="Date Created"
            suppressColumnsToolPanel={true}
            field="date_created"
            cellRenderer="format_date"
            editable={false}
          />
          {permission.write && (
            <AgGridColumn
              headerName="Remove"
              suppressColumnsToolPanel={true}
              field="id"
              cellRenderer={'RenderRemove'}
              editable={false}
            />
          )}
        </AgGridReact>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.main.user,
    component: state.main.component,
  };
}

const mapDispatchToProps = {
  dispatchComponent: setComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManager);
