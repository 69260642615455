// eslint-disable
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import { combineReducers } from 'redux';
import { DashboardReducer } from './DashBoardReducer';
import { NotificationReducer } from './NotificationReducer';
import { MainReducer } from './MainReducer';

export const rootReducer = combineReducers({
  dashboard: DashboardReducer,
  notification: NotificationReducer,
  main: MainReducer,
});
