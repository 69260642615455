/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import './Notification.css';
import NotificationTable from './wigets/NotificationTable';
import permissions from '../../services/Permissions';
import Button from '@atlaskit/button';
import EditNotificationModal from './wigets/EditNotificationModal';
import AvailableNotifications from './wigets/AvailableNotifications';
import CreateNotificationModal from './wigets/CreateNotificationModal';

const Notification = () => {
  const [NotificationId, setNotificationId] = useState<{ id: string; toProduct: string } | null>(
    null,
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [permission, setPermissions] = useState({
    write: false,
    read: false,
  });
  const [product, setProduct] = useState('');

  const firstProduct = (value: string) => {
    setProduct(value);
  };

  useEffect(() => {
    void permissions.checkPermissions('notifications').then((permission) => {
      setPermissions(permission);
    });
  }, []);

  return (
    <div className="notifications-main-container">
      <div className="notification-modal-container">
        <div className="notification-button-main">
          <AvailableNotifications
            setProduct={setProduct}
            product={product}
            firstProduct={firstProduct}
          />
          {permission.write ? (
            <Button
              className="notification-button-Create-notification"
              appearance="primary"
              onClick={() => {
                setIsCreateOpen(true);
              }}>
              Create Notification
            </Button>
          ) : (
            <></>
          )}
        </div>
        {isCreateOpen && permission.write ? (
          <CreateNotificationModal
            setIsUpdated={setIsUpdated}
            closeModal={() => setIsCreateOpen(false)}
            product={product}
          />
        ) : (
          <> </>
        )}
        {isEditOpen && NotificationId && permission.write ? (
          <EditNotificationModal
            setIsUpdated={setIsUpdated}
            closeModal={() => setIsEditOpen(false)}
            NotificationId={NotificationId}
            product={product}
          />
        ) : (
          <> </>
        )}
      </div>
      <NotificationTable
        setId={setNotificationId}
        permission={permission}
        isUpdated={isUpdated}
        setIsOpen={setIsEditOpen}
        setIsUpdated={setIsUpdated}
        product={product}
      />
    </div>
  );
};

export default Notification;
