import { useCallback, useEffect, useState } from 'react';
import { CheckboxSelect, OptionType, ValueType } from '@atlaskit/select';
import { getFullUser } from '../../../api/host/users';
import {
  useAccountsLazyQuery,
  useCreateUserMutation,
  useUserLazyQuery,
  useUserQuery,
  useUsersLazyQuery,
  useUsersQuery,
} from '../../../graphql/generated';
import { JiraUserType } from '../../../types/JiraUserType';

const UserSelector = ({
  defaultValue,
  UserOnChange,
  selectedValues,
  product,
}: {
  defaultValue?: string[];
  UserOnChange: (user: OptionType[]) => void;
  selectedValues: {
    accounts: string[];
  };
  product?: string;
}) => {
  const [toAll, setToAll] = useState(true);
  const [users, setUsers] = useState<{ id: string; displayName: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<OptionType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [getAccounts] = useAccountsLazyQuery({
    onError: (error) => {
      console.log(error);
    },
  });

  const [getUsers] = useUsersLazyQuery();

  const [createUserMutation] = useCreateUserMutation({
    onError: (error) => {
      console.log(error);
    },
  });

  const setSelected = (user: OptionType[]) => {
    setSelectedUsers(user);
    UserOnChange(user);
  };

  function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout;
    return (...args: Params) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }

  const options = (users: { id: string; displayName: string }[]) => {
    return [
      { value: 'All', label: 'All' },
      ...users
        .map((user) => {
          return {
            value: user.id,
            label: user.displayName,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  };

  const setUsersList = async () => {
    setIsLoading(true);
    if (selectedValues?.accounts?.length && product && product !== 'undefined') {
      try {
        const accounts = (
          await getAccounts({
            variables: {
              ids: selectedValues.accounts,
            },
          })
        ).data?.accounts;
        const users = await getFullUser(
          accounts?.map((account) => String(account?.clientKey)) || [],
          product,
          searchQuery,
        );

        const usersSelected: JiraUserType[] = [];

        const ids = selectedUsers.map((user) => String(user.value));
        if (defaultValue) {
          ids.push(...defaultValue);
        }

        const { data } = await getUsers({ variables: { ids } });

        const selectedUsersFetched = data?.users.map(async (user) => {
          const users = await getFullUser(
            accounts?.map((account) => String(account?.clientKey)) || [],
            product,
            String(user.userId),
          );
          return users[0];
        });

        if (selectedUsersFetched) {
          usersSelected.push(...(await Promise.all(selectedUsersFetched)));
        }
        const fetchedUsers = await Promise.all(
          [...users, ...usersSelected]?.map(async (user) => {
            const newUser = await createUserMutation({
              variables: {
                createUserInput: {
                  accountId:
                  accounts?.find((account) => user.self.startsWith(String(account?.baseUrl)))
                      ?.id || '',
                  userId: user.accountId || '',
                },
              },
            });
            return {
              ...user,
              ...newUser.data?.createUser,
            };
          }),
        );

        if (fetchedUsers.length) {
          setUsers([
            ...fetchedUsers.map((user) => ({
              displayName: user.displayName || '',
              id: user.id || '',
            })),
          ]);
        } else {
          setUsers([]);
        }
        setIsLoading(false);
      } catch (error) {
        setUsers([]);
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const usersOnChange = (users: ValueType<OptionType, true>) => {
    const isAll = users.filter((user) => user.value === 'All');
    if (isAll.length && !toAll) {
      setToAll(true);
      setSelected(isAll);
    } else if (users.length > 0) {
      setToAll(false);
      setSelected(users.filter((acc) => acc.value !== 'All'));
    } else {
      setToAll(true);
      setSelected([{ value: 'All', label: 'All' }]);
    }
  };

  useEffect(() => {
    setSelected([{ value: 'All', label: 'All' }]);
    setToAll(true);
    void setUsersList();
  }, []);

  useEffect(() => {
    if (users.length > 0 && defaultValue && defaultValue.length > 0 && toAll) {
      setSelected(options(users).filter((user) => defaultValue.includes(user.value)));
      setToAll(false);
    }
  }, [users, toAll]);

  useEffect(() => {
    setSelected([
      {
        value: 'All',
        label: 'All',
      },
    ]);
  }, [selectedValues.accounts]);

  useEffect(() => {
    void setUsersList();
  }, [selectedValues.accounts, searchQuery]);

  return (
    <CheckboxSelect
      isLoading={isLoading}
      inputId="checkbox-select-example"
      className="multi-select-container"
      classNamePrefix="select"
      onInputChange={debounce((inputValue) => setSearchQuery(inputValue), 1000)}
      onChange={(users) => usersOnChange(users)}
      value={selectedUsers}
      options={options(users)}
      placeholder="To User"
    />
  );
};
export default UserSelector;
