export const ADDONS_MAP = {
  'time-in-status-report': 'TIS',
  'sla-time-and-report': 'SLA',
  'time-between-statuses': 'TBS',
  'issue-history': 'IH',
  'issue-history-lite': 'IH Lite',
  'time-in-status-report-lite': 'TIS Lite',
  rm: 'RM',
  'issue-manager': 'VA',
  'google-maps-for-jira': 'Maps',
  'dynamic-filters': 'DF',
  bpm: 'BPM',
  'saasjet-hrm': 'HRM',
  jetforms: 'Forms for Jira',
  'recurring-checklists': 'Checklists',
};
