import React from 'react';
import Spinner from '@atlaskit/spinner';

const Loading = () => {
  return (
    <>
      <div className="spinner-container">
        <div className="center-spinner-block">
          <Spinner size={150} />
        </div>
      </div>
    </>
  );
};

export default Loading;
