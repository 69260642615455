import React, { useEffect, useState } from 'react';
import getAccounts, { getAccountById } from '../../../api/host/accounts';
import Select, { components } from '@atlaskit/select';
import { getShortUrl } from '../../../utlils';
import Tooltip from '@atlaskit/tooltip';
import { selectorStyle } from '../selectorStyles';
import { setUpdateJsonEditor } from '../../../redux/actions/dash-board-actions';
import { connect } from 'react-redux';

const AccountSelectorTest = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAccount, setSelectedAccount] = useState({
    label: 'None',
    value: null,
  });
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const url = new URL(document.URL).searchParams;
  let accountUrl = url.get('account');

  const debounce = (cb, delay = 1000) => {
    return (...args) => {
      clearTimeout(debounceTimeout);
      setIsLoading(true);
      if (args[1].action === 'menu-close') {
        setIsLoading(false);
      }
      setDebounceTimeout(
        setTimeout(() => {
          cb(...args);
        }, delay),
      );
    };
  };

  const setAccountsList = () => {
    if (props.selectedValues?.product?.value) {
      getAccounts(props.selectedValues?.product?.value, null, searchQuery)
        .then((response) => {
          const result = response.filter((e) => e.installed.clientKey !== null);
          setAccounts(result);
          setIsLoading(false);
        })
        .catch(() => {
          setAccounts([]);
          setIsLoading(false);
        });
    } else {
      setAccounts([]);
      setIsLoading(false);
    }
  };

  const isSelectedValidForProduct = (
    clientKey = props.selectedAccount?.installed?.clientKey,
    product = props.selectedValues?.product?.value,
  ) => {
    if (!clientKey && accountUrl) {
      clientKey = accountUrl;
    } else if (!clientKey && !accountUrl) {
      return setSelectedAccount({
        label: 'None',
        value: null,
      });
    }
    getAccountById(product, clientKey)
      .then((response) => {
        if (response?.installed?.clientKey && response?.installed?.baseUrl) {
          setSelectedAccount({
            label: getShortUrl(response.installed.baseUrl),
            value: response.installed.clientKey,
          });
        } else {
          setSelectedAccount({
            label: 'None',
            value: null,
          });
        }
        props.setStoreAccount(response);
        props.setStoreJiraJSON(response);
      })
      .catch(() => {
        setSelectedAccount({
          label: 'None',
          value: null,
        });
      });
  };

  const accountOnChange = (account) => {
    isSelectedValidForProduct(account.value);
  };

  useEffect(() => {
    isSelectedValidForProduct();
    setAccountsList();
  }, []);

  useEffect(() => {
    isSelectedValidForProduct();
  }, [props.selectedValues.product.value]);

  useEffect(() => {
    setAccountsList();
  }, [props.selectedValues.product.value, searchQuery]);

  return (
    <>
      {props.selectedValues?.product?.value ? (
        <Select
          isLoading={isLoading}
          components={{ Option }}
          className="select-container"
          inputId="grouped-options-example"
          value={selectedAccount}
          onInputChange={debounce((inputValue) => setSearchQuery(inputValue))}
          styles={selectorStyle}
          onChange={(account) => accountOnChange(account)}
          options={props.options(accounts).sort((a, b) => a.label.localeCompare(b.label))}
        />
      ) : (
        <> </>
      )}
    </>
  );
};

const Option = (props) => {
  const { data } = props;
  const { tooltipDescription, tooltipPosition } = data;
  return (
    <Tooltip content={tooltipDescription} position={tooltipPosition}>
      <components.Option {...props} />
    </Tooltip>
  );
};

function mapStateToProps(state) {
  return {
    setUpdate: state.dashboard.setUpdate,
  };
}

const mapDispatchToProps = {
  dispatchUpdateJsonEditor: setUpdateJsonEditor,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelectorTest);
