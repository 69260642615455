export const prompt = `const myAsyncFunction = async () => {
  const baseUrl = "{{baseUrl}}";
   const email = "{{email}}";
   const token = "{{token}}";
   
   const yesterday = new Date();
   yesterday.setDate(yesterday.getDate() - 1);
   
   const formattedDate = yesterday.toISOString().substring(0, 10);
   
   const url = \`\${baseUrl}/rest/api/2/search?jql=created>=\${formattedDate}\`;
   
  return await fetch(url, {
     headers: {
       Authorization: \`Basic \${btoa(\`\${email}:\${token}\`)}\`,
       "Content-Type": "application/json",
     },
   })
     .then((response) => response.json())
     .catch((error) => console.error(error));
 }
 return await myAsyncFunction()`
