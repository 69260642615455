import axios from '../axiosClient';

export const getTransactionByLicenceId = (licenseId: string) => {
  return axios
    .get(`api/marketplace/transactions/${licenseId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error) {
        throw new Error("Can't load transactions'");
      }
    });
};
