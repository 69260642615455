import React, { useEffect, useState } from 'react';
import '../Pivot.css';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import getQueries, { changeQuery, removeQuery } from '../../../api/host/query';
import Button from '@atlaskit/button';
import { createLog } from '../../../api/host/internal-logs';
import { deleted_query, renamed_query } from '../../../utlils/logsMessages';
import { toast } from 'react-toastify';
import ModalSubmit from '../../../utlils/ModalSubmit';

const QueryTable = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    getQueries()
      .then((response) => {
        props.setAllQueries(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    return changeQuery(event).then(() => {
      createLog(renamed_query, 200, { changed_from: event.oldValue, changed_to: event.newValue });
      toast.success('Query updated successfully!');
    });
  };

  const formatDate = (cell) => {
    return moment(cell.value).format('MMM DD, YYYY HH:mm:ss');
  };

  const deleteRender = (cell) => {
    const onDelete = () => {
      removeQuery(cell.value).then((response) => {
        props.setAllQueries(response);
        toast.success('Query removed successfully!');
        createLog(deleted_query, 200, cell.value);
      });
    };
    return (
      <>
        <div className="query-table-button">
          <Button
            appearance={'primary'}
            type="checkbox"
            onClick={() => {
              props.setSelected(cell.data.query);
              props.onClose();
              props.updatePivot(cell.data.query);
            }}>
            apply
          </Button>
          <ModalSubmit
            buttonName={'X'}
            submit={onDelete}
            className={'delete-button'}
            text={'Delete Preset?'}
          />
        </div>
      </>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        id="myGrid"
        style={{
          height: '400px',
          width: '100%',
        }}
        className="ag-theme-alpine">
        <AgGridReact
          enableRangeSelection={true}
          pagination={true}
          rowHeight={35}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            editable: true,
            minWidth: 180,
          }}
          rowData={props.queries}
          onCellValueChanged={onCellValueChanged}
          autoGroupColumnDef={{
            headerName: 'Group',
            minWidth: 250,
            field: 'model',
            rowGroupIndex: 1,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
          }}
          frameworkComponents={{
            format_date: formatDate,
            delete: deleteRender,
          }}
          onGridReady={onGridReady}></AgGridReact>
      </div>
    </div>
  );
};

const columnDefs = [
  {
    headerName: 'Updated By',
    field: 'user_name',
    sort: 'asc',
    cellStyle: { display: 'flex', alignItems: 'center' },
    editable: false,
  },
  {
    headerName: 'Name',
    field: 'name',
    cellStyle: { display: 'flex', alignItems: 'center' },
    editable: true,
  },
  {
    headerName: 'Query',
    field: 'query',
    cellStyle: { display: 'flex', alignItems: 'center' },
    editable: false,
  },
  {
    headerName: 'Date Created',
    field: 'date',
    cellStyle: { display: 'flex', alignItems: 'center' },
    suppressColumnsToolPanel: true,
    editable: false,
    cellRenderer: 'format_date',
  },

  {
    headerName: 'Actions',
    field: 'query',
    cellStyle: { display: 'flex', justifyContent: 'flex-end' },
    editable: false,
    cellRenderer: 'delete',
  },
];

export default QueryTable;
