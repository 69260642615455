/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountModel = {
  __typename?: 'AccountModel';
  baseUrl: Scalars['String'];
  clientKey: Scalars['String'];
  id: Scalars['String'];
};

export type AuthorizationModel = {
  __typename?: 'AuthorizationModel';
  addonKey: Scalars['String'];
  id: Scalars['String'];
  jwtUrl: Scalars['String'];
};

export type CategoryModel = {
  __typename?: 'CategoryModel';
  background: Scalars['String'];
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreateAccountInput = {
  /** Url for client jira */
  baseUrl: Scalars['String'];
  /** Jira client key */
  clientKey: Scalars['String'];
};

export type CreateAuthorizationRouteInput = {
  /** Addon key */
  addonKey: Scalars['String'];
  /** URL for jwt token verification */
  jwtUrl: Scalars['String'];
};

export type CreateCategoryInput = {
  /** Background of the category */
  background: Scalars['String'];
  /** Color of the category */
  color: Scalars['String'];
  /** Name of the category */
  name: Scalars['String'];
};

export type CreateDestinationInput = {
  /** Send notifications to listed accounts */
  toAccount?: InputMaybe<Array<Scalars['String']>>;
  /** Send notifications to all users */
  toAll: Scalars['Boolean'];
  /** Send notification to listed users */
  toUser?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateNotificationInput = {
  /** Addon key for notification */
  addonKey: Scalars['String'];
  /** Category of notification ref */
  category: Scalars['String'];
  /** Date when notification is created */
  dateOfCreation: Scalars['Float'];
  /** Destination */
  destination: CreateDestinationInput;
  /** Display notification for new users */
  hideFromNewUsers: Scalars['Boolean'];
  /** Notification message */
  message: Scalars['String'];
  /** Date when notification is published */
  publicationDate: Scalars['Float'];
  /** Notification title */
  title: Scalars['String'];
  /** Users that viewed notification ref */
  watchedBy?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateUserInput = {
  /** Jira client key ref */
  accountId: Scalars['String'];
  /** Jira user id */
  userId: Scalars['String'];
};

export type DestinationModel = {
  __typename?: 'DestinationModel';
  id: Scalars['String'];
  toAccount?: Maybe<Array<AccountModel>>;
  toAll: Scalars['Boolean'];
  toUser?: Maybe<Array<UserModel>>;
};

export type FindByAddonKeyInput = {
  /** Addon key */
  addonKey: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: AccountModel;
  createAuthorizationRoute: AuthorizationModel;
  createCategory: CategoryModel;
  createNotification: NotificationModel;
  createUser: UserModel;
  deleteNotification: Scalars['String'];
  dislikeNotification: NotificationModel;
  likeNotification: NotificationModel;
  updateCategory: CategoryModel;
  updateNotification: NotificationModel;
  watchNotification: NotificationModel;
};


export type MutationCreateAccountArgs = {
  createAccountInput: CreateAccountInput;
};


export type MutationCreateAuthorizationRouteArgs = {
  createNotificationInput: CreateAuthorizationRouteInput;
};


export type MutationCreateCategoryArgs = {
  createCategoryInput: CreateCategoryInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDislikeNotificationArgs = {
  id: Scalars['String'];
};


export type MutationLikeNotificationArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  updateCategoryInput: UpdateCategoryInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationWatchNotificationArgs = {
  id: Scalars['String'];
};

export type NotificationCreated = {
  __typename?: 'NotificationCreated';
  addon_key: Scalars['String'];
  event: Scalars['String'];
};

export type NotificationModel = {
  __typename?: 'NotificationModel';
  addonKey: Scalars['String'];
  category: CategoryModel;
  dateOfCreation: Scalars['Float'];
  destination: DestinationModel;
  hideFromNewUsers: Scalars['Boolean'];
  id: Scalars['String'];
  likedBy?: Maybe<Array<UserModel>>;
  message: Scalars['String'];
  publicationDate: Scalars['Float'];
  title: Scalars['String'];
  watchedBy?: Maybe<Array<UserModel>>;
};

export type Query = {
  __typename?: 'Query';
  accountByBaseUrl: AccountModel;
  accountByClientKey: AccountModel;
  accountById: AccountModel;
  accounts: Array<AccountModel>;
  authorizationRouteByAddonKey: AuthorizationModel;
  authorizationRoutes: Array<AuthorizationModel>;
  availableNotifications: Array<NotificationModel>;
  categories: Array<CategoryModel>;
  category: CategoryModel;
  notification: NotificationModel;
  notifications: Array<NotificationModel>;
  notificationsByAddonKey: Array<NotificationModel>;
  unreadNotificationCount: Scalars['Float'];
  user: UserModel;
  userById: UserModel;
  users: Array<UserModel>;
};


export type QueryAccountByBaseUrlArgs = {
  baseUrl: Scalars['String'];
};


export type QueryAccountByClientKeyArgs = {
  clientKey: Scalars['String'];
};


export type QueryAccountByIdArgs = {
  id: Scalars['String'];
};


export type QueryAccountsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryAuthorizationRouteByAddonKeyArgs = {
  findByAddonKeyInput: FindByAddonKeyInput;
};


export type QueryCategoryArgs = {
  name: Scalars['String'];
};


export type QueryNotificationArgs = {
  id: Scalars['String'];
};


export type QueryNotificationsByAddonKeyArgs = {
  addonKey: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserByIdArgs = {
  userId: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated: NotificationCreated;
};

export type UpdateCategoryInput = {
  /** Background of the category */
  background?: InputMaybe<Scalars['String']>;
  /** Color of the category */
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Name of the category */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationInput = {
  /** Addon key for notification */
  addonKey?: InputMaybe<Scalars['String']>;
  /** Category of notification ref */
  category?: InputMaybe<Scalars['String']>;
  /** Date when notification is created */
  dateOfCreation?: InputMaybe<Scalars['Float']>;
  /** Destination */
  destination?: InputMaybe<CreateDestinationInput>;
  /** Display notification for new users */
  hideFromNewUsers?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** Notification message */
  message?: InputMaybe<Scalars['String']>;
  /** Date when notification is published */
  publicationDate?: InputMaybe<Scalars['Float']>;
  /** Notification title */
  title?: InputMaybe<Scalars['String']>;
  /** Users that viewed notification ref */
  watchedBy?: InputMaybe<Array<Scalars['String']>>;
};

export type UserModel = {
  __typename?: 'UserModel';
  accountId: AccountModel;
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateAccountMutationVariables = Exact<{
  createAccountInput: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } };

export type CreateNotificationMutationVariables = Exact<{
  createNotificationInput: CreateNotificationInput;
}>;


export type CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } } };

export type CreateUserMutationVariables = Exact<{
  createUserInput: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } } };

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification: string };

export type UpdateNotificationMutationVariables = Exact<{
  updateNotificationInput: UpdateNotificationInput;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } } };

export type AccountByBaseUrlQueryVariables = Exact<{
  baseUrl: Scalars['String'];
}>;


export type AccountByBaseUrlQuery = { __typename?: 'Query', accountByBaseUrl: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } };

export type AccountByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountByIdQuery = { __typename?: 'Query', accountById: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } };

export type AccountsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }> };

export type CategoryQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type CategoryQuery = { __typename?: 'Query', category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string } };

export type NotificationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type NotificationQuery = { __typename?: 'Query', notification: { __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } } };

export type NotificationsByAddonKeyQueryVariables = Exact<{
  addonKey: Scalars['String'];
}>;


export type NotificationsByAddonKeyQuery = { __typename?: 'Query', notificationsByAddonKey: Array<{ __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } }> };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } }> };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } } };

export type UsersQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> };


export const CreateAccountDocument = gql`
    mutation CreateAccount($createAccountInput: CreateAccountInput!) {
  createAccount(createAccountInput: $createAccountInput) {
    id
    clientKey
    baseUrl
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      createAccountInput: // value for 'createAccountInput'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateNotificationDocument = gql`
    mutation CreateNotification($createNotificationInput: CreateNotificationInput!) {
  createNotification(createNotificationInput: $createNotificationInput) {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      createNotificationInput: // value for 'createNotificationInput'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($createUserInput: CreateUserInput!) {
  createUser(createUserInput: $createUserInput) {
    id
    accountId {
      id
      clientKey
      baseUrl
    }
    userId
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($id: String!) {
  deleteNotification(id: $id)
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($updateNotificationInput: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $updateNotificationInput) {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      updateNotificationInput: // value for 'updateNotificationInput'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const AccountByBaseUrlDocument = gql`
    query AccountByBaseUrl($baseUrl: String!) {
  accountByBaseUrl(baseUrl: $baseUrl) {
    id
    clientKey
    baseUrl
  }
}
    `;

/**
 * __useAccountByBaseUrlQuery__
 *
 * To run a query within a React component, call `useAccountByBaseUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountByBaseUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountByBaseUrlQuery({
 *   variables: {
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useAccountByBaseUrlQuery(baseOptions: Apollo.QueryHookOptions<AccountByBaseUrlQuery, AccountByBaseUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountByBaseUrlQuery, AccountByBaseUrlQueryVariables>(AccountByBaseUrlDocument, options);
      }
export function useAccountByBaseUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountByBaseUrlQuery, AccountByBaseUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountByBaseUrlQuery, AccountByBaseUrlQueryVariables>(AccountByBaseUrlDocument, options);
        }
export type AccountByBaseUrlQueryHookResult = ReturnType<typeof useAccountByBaseUrlQuery>;
export type AccountByBaseUrlLazyQueryHookResult = ReturnType<typeof useAccountByBaseUrlLazyQuery>;
export type AccountByBaseUrlQueryResult = Apollo.QueryResult<AccountByBaseUrlQuery, AccountByBaseUrlQueryVariables>;
export const AccountByIdDocument = gql`
    query AccountById($id: String!) {
  accountById(id: $id) {
    id
    clientKey
    baseUrl
  }
}
    `;

/**
 * __useAccountByIdQuery__
 *
 * To run a query within a React component, call `useAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<AccountByIdQuery, AccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountByIdQuery, AccountByIdQueryVariables>(AccountByIdDocument, options);
      }
export function useAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountByIdQuery, AccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountByIdQuery, AccountByIdQueryVariables>(AccountByIdDocument, options);
        }
export type AccountByIdQueryHookResult = ReturnType<typeof useAccountByIdQuery>;
export type AccountByIdLazyQueryHookResult = ReturnType<typeof useAccountByIdLazyQuery>;
export type AccountByIdQueryResult = Apollo.QueryResult<AccountByIdQuery, AccountByIdQueryVariables>;
export const AccountsDocument = gql`
    query Accounts($ids: [String!]!) {
  accounts(ids: $ids) {
    id
    clientKey
    baseUrl
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    id
    name
    color
    background
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query Category($name: String!) {
  category(name: $name) {
    id
    name
    color
    background
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const NotificationDocument = gql`
    query Notification($id: String!) {
  notification(id: $id) {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationQuery__
 *
 * To run a query within a React component, call `useNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationQuery(baseOptions: Apollo.QueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
      }
export function useNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationQuery, NotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationQuery, NotificationQueryVariables>(NotificationDocument, options);
        }
export type NotificationQueryHookResult = ReturnType<typeof useNotificationQuery>;
export type NotificationLazyQueryHookResult = ReturnType<typeof useNotificationLazyQuery>;
export type NotificationQueryResult = Apollo.QueryResult<NotificationQuery, NotificationQueryVariables>;
export const NotificationsByAddonKeyDocument = gql`
    query NotificationsByAddonKey($addonKey: String!) {
  notificationsByAddonKey(addonKey: $addonKey) {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationsByAddonKeyQuery__
 *
 * To run a query within a React component, call `useNotificationsByAddonKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsByAddonKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsByAddonKeyQuery({
 *   variables: {
 *      addonKey: // value for 'addonKey'
 *   },
 * });
 */
export function useNotificationsByAddonKeyQuery(baseOptions: Apollo.QueryHookOptions<NotificationsByAddonKeyQuery, NotificationsByAddonKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsByAddonKeyQuery, NotificationsByAddonKeyQueryVariables>(NotificationsByAddonKeyDocument, options);
      }
export function useNotificationsByAddonKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsByAddonKeyQuery, NotificationsByAddonKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsByAddonKeyQuery, NotificationsByAddonKeyQueryVariables>(NotificationsByAddonKeyDocument, options);
        }
export type NotificationsByAddonKeyQueryHookResult = ReturnType<typeof useNotificationsByAddonKeyQuery>;
export type NotificationsByAddonKeyLazyQueryHookResult = ReturnType<typeof useNotificationsByAddonKeyLazyQuery>;
export type NotificationsByAddonKeyQueryResult = Apollo.QueryResult<NotificationsByAddonKeyQuery, NotificationsByAddonKeyQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(id: $id) {
    id
    accountId {
      id
      clientKey
      baseUrl
    }
    userId
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($ids: [String!]!) {
  users(ids: $ids) {
    id
    accountId {
      id
      clientKey
      baseUrl
    }
    userId
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;