import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from '../../history';
import DashBoard from '../DashBoard/DashBoard';
import Notification from '../Notification/Notification';
import UserManager from '../UserManager/UserManager';
import Pivot from '../Pivot/Pivot';
import permissions from '../../services/Permissions';
import { connect } from 'react-redux';
import SideBar from '../SideBar/SideBar';
import Playground from "../Playground/Playground"
import Button from '@atlaskit/button/standard-button';
import EmptyState from '@atlaskit/empty-state';
import styled from 'styled-components';
import './Main.css';
import Loading from '../../utlils/Loading';
import Logs from '../Logs/Logs';
import { UserType } from '../../types/UserType';
import { SectionsType } from '../../types/SectionsType';

class Expand {
  public expanded: boolean;

  constructor(isExpanded: boolean) {
    this.expanded = isExpanded;
  }
  setExpanded = (isExpanded: boolean) => {
    this.expanded = isExpanded;
  };
}

export const expand = new Expand(true);

const Main = (props: { user?: UserType; component: string; Logout: () => void }) => {
  const [sections, setSections] = useState<SectionsType>({
    'logs-system': false,
    'user-manager': false,
    dashboard: false,
    hubspot: false,
    logs: false,
    notifications: false,
    permissions: false,
    pivots: false,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (props.user?.id) {
      permissions
        .setUser(props.user)
        .getAvailableSections()
        .then((sections: SectionsType) => {
          setSections(sections);
          setIsLoaded(true);
        })
        .catch(() => {});
    }
  }, [props.user]);

  const onReload = () => {
    return window.location.reload();
  };

  const isAvailableSection = () => {
    return Object.keys(sections).some((section) => {
      //@ts-ignore
      return section !== 'permissions' && sections[section] === true;
    });
  };

  const Main = styled.main`
    position: relative;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
    padding: 70px 10px 10px 10px;
    width: 100%;
    @media (max-width: 1000px) {
      margin-left: ${props.component === '/' ? '65px' : '0px'};
    }
    @media (max-width: 1000px) {
      width: calc(100% - 65px);
    }
  `;

  return (
    <>
      {isLoaded ? (
        <>
          {Object.keys(sections).length && isAvailableSection() ? (
            <>
              <Header Logout={props.Logout} />
              <div className="main-container">
                <SideBar sections={sections} expand={expand} />
                <Router history={history}>
                  <Main>
                    <Switch>
                      {sections.dashboard ? (
                        <Route exact path="/" component={DashBoard} />
                      ) : (
                        () => {}
                      )}
                      {sections.notifications ? (
                        <Route exact path="/notifications" component={Notification} />
                      ) : (
                        () => {}
                      )}
                      {sections['user-manager'] ? (
                        <Route exact path="/usermanager" component={UserManager} />
                      ) : (
                        () => {}
                      )}
                      {sections.pivots ? <Route exact path="/pivot" component={Pivot} /> : () => {}}
                      {sections['logs-system'] ? (
                        <Route exact path="/internalLogs" component={Logs} />
                      ) : (
                        () => {}
                      )}
                      <Route exact path="/playground" component={Playground} />
                    </Switch>
                  </Main>
                </Router>
              </div>
            </>
          ) : (
            <>
              <div className="no-permissions-container">
                <div className="no-permissions">
                  <EmptyState
                    header="You don't have access to admin panel"
                    description="Ask a project admin for permission to see the admin panel."
                    primaryAction={
                      <Button appearance="primary" onClick={onReload}>
                        Reload Page
                      </Button>
                    }
                    imageUrl={
                      'https://atlassian.design/static/LockClosed-3676d607e7ded3afddcd12fbf1bed02c.png'
                    }
                    maxImageHeight={160}
                    maxImageWidth={160}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

function mapStateToProps(state:any) {
  return {
    user: state.main.user,
    component: state.main.component,
  };
}

export default connect(mapStateToProps)(Main);
