import React from 'react';
import './Header.css';
import { googleLogout } from '@react-oauth/google';
import Avatar from '@atlaskit/avatar';
import { connect } from 'react-redux';
import { logo, LogoutButton } from '../../images';
import { UserType } from '../../types/UserType';

const Header = (props: { user: UserType; Logout: () => void }) => {
  return (
    <div className="header-container">
      <div className="header-logotype">{logo}</div>
      <div className="header-user-info-container">
        <div className="user-avatar">
          <Avatar size="large" src={props.user.imageUrl} />
        </div>
        <p id="info-block"> {props.user.email} </p>
        {/*<button*/}
        {/*  className="logout-button_slide"*/}
        {/*  onClick={() => {*/}
        {/*    googleLogout();*/}
        {/*    props.Logout(false);*/}
        {/*  }}*/}
        {/*/>*/}
        {/*Logout {LogoutButton}*/}
        {/*<button />*/}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.main.user,
  };
}

export default connect(mapStateToProps)(Header);
