import React, { useEffect, useRef, useState } from "react";
import TextArea from '@atlaskit/textarea';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import { prompt } from './defaultPrompt';
import axios from "axios";
//@ts-ignore
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Spinner from "@atlaskit/spinner";
import "./Playground.css"

function Playground() {
    const [codeInput, setCodeInput] = useState(prompt || "");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [baseUrl, setBaseUrl] = useState("https://saasjet.atlassian.net");
    const [output, setOutput] = useState("");
    const [processing, setProcessing] = useState(false);
    const JsonEditorRef = useRef(null);


    const userRef = (instance: { jsonEditor: any; }) => {
        if (instance) {
            JsonEditorRef.current = instance.jsonEditor;
        } else {
            JsonEditorRef.current = null;
        }
    };


    useEffect(() => {
        if (JsonEditorRef.current !== null) {
            //@ts-ignore
            JsonEditorRef.current.set(output);
        }
    }, [output]);

    async function runCode() {
        try {
            setProcessing(true)
            const input = codeInput
                .replaceAll("{{baseUrl}}", baseUrl)
                .replaceAll("{{email}}", email)
                .replaceAll("{{token}}", token)
            console.log(input);
            const result = await axios.post("/api/playground", { script: input })

            setOutput(result.data);
            setProcessing(false)
        } catch (error) {
            setOutput(String(error));
        }
    }

    return (
        <div style={{ fontSize: "14px", height: "100%", overflow: "auto" }}>
            <label htmlFor="email">Email:</label>
            <TextField value={email} onChange={(e: any) => {

                setEmail(e.target.value)
            }} name="email" />
            <br />
            <label htmlFor="token">Token:</label>
            <TextField value={token} onChange={(e: any) => setToken(e.target.value)} name="token" />
            <br />
            <label htmlFor="baseurl">BaseUrl:</label>
            <TextField value={baseUrl} onChange={(e: any) => setBaseUrl(e.target.value)} placeholder="https://saasjet.atlassian.net" name="baseurl" />
            <br />

            <i>Recommended prompt:<br />
                <div>
                    Give me a JS script, but replace baseUrl, email and token with " &#123; &#123;*expression* &#125; &#125;". Result should be returted as return await result. Do not show imports and don't do logs.
                    In order to <br /><br />

                    (get all issues created yesterday)
                </div>
            </i><br /><br />

            <label htmlFor="js-input">Enter JavaScript code:</label>
            <br />
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                    <TextArea
                        id="js-input"
                        value={codeInput}
                        onChange={(e: any) => setCodeInput(e.target.value)}
                    />
                    <br />
                    <Button onClick={runCode} appearance="primary">Run</Button>
                    <br />
                </div>
                <div className="playground-editor" style={{ width: "100%" }}>
                    {!processing ?
                        (
                            typeof output === "object" ?
                                <Editor
                                    ref={userRef}
                                    value={output}
                                />
                                :
                                <TextArea
                                    value={output}
                                />
                        )

                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner size={30} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Playground;