import React, { useEffect } from 'react';
import './LoginPage.css';
import { useGoogleLogin } from '@react-oauth/google';
import Particles from 'react-tsparticles';
import particles_config from './particles_config.json';

const LoginPage = ({ responseGoogle }) => {
  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    flow: 'implicit',
  });

  const onLoadedLogin = useGoogleLogin({
    prompt: 'none',
    onSuccess: responseGoogle,
    flow: 'implicit',
  });

  useEffect(() => {
    onLoadedLogin();
  }, [onLoadedLogin]);

  return (
    <>
      <div className="logo-container">
        <img
          className="logotype"
          src="https://saasjet.com/wp-content/uploads/2021/07/logo.svg"
          width="225"
          alt="SaaSJet – Gold Atlassian Marketplace Partner"
        />
        <div className="container">
          <div className="login-container">
            <div className="login-form">
              <p id="greeting"> Welcome to SaaSJet Admin </p>
              <p> Apps for Skyrocketing Efficiency </p>
              <button className="login-button_slide login-slide_right" onClick={() => login()}>
                Login
              </button>
            </div>
          </div>
          <Particles params={particles_config} />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
