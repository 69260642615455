import moment from 'moment';

export function getShortUrl(account: string) {
  try {
    if (!account) {
      return '';
    }

    return account.replace(/^https?:\/\//, '').replace(/\..*/, '');
  } catch (e) {
    console.log(e);
    return '';
  }
}

export function getPaginatedUser(response) {
  return response.flat().reduce((users, item) => {
    const paginatedUsers = users.findIndex((user) => user.displayName === item.displayName);
    if (paginatedUsers === -1) {
      users.push(item);
    }
    return users;
  }, []);
}

export function viewLogs(message: string, atlassianName: string, date: string, product: string) {
  return window.open(
    `https://app.datadoghq.eu/logs?query=%40service%3A${product}%20%40atlassian_name%3A${atlassianName}%20%40log_msg%3A%28%22Changed%20order%20of%20status%20columns%20of%20grid%22%20OR%20%22Changed%20user%20data%20on%20gadget%22%20OR%20%22Viewer%20parameters%22%20OR%20%22Changed%20filter%22%20OR%20%22Gadget%20object%20assign%22%20OR%20%22Changed%20jira%20data%20on%20gadget%22%20OR%20%22Gadget%20render%22%20OR%20%22Changed%20type%20of%20report%22%20OR%20%22Changed%20date%20-%20range%20of%20grid%22%20OR%20%22Smart%20view%20switch%22%20OR%20%22Sort%20settings%20changed%22%20OR%20%22Addon%20updated%20or%20reinstalled%22%20OR%20%22Authentificate%20new%20user%22%20OR%20%22New%20export%20started%22%20OR%20%22User%20uploaded%20the%20exported%20file%22%20OR%20%22User%20downloaded%20file%22%20OR%20%22Sort%20settings%20changed%20to%20default%22%20OR%20%22Changed%20custom%20groups%20of%20grid%22%20OR%20%22Addon%20installation%22%20OR%20%22Addon%20uninstalled%22%20OR%20%22Addon%20uninstalled%20but%20undefined%20account%22%20OR%20%22Addon%20updated%20or%20reinstalled%22%20OR%20status%3A%28error%20OR%20warn%29%29%20status%3A%28${message}%29&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&from_ts=${+moment(
      date,
    )}&to_ts=${+moment(date).add(1, 'hours')}&live=false`,
  );
}
