import React, { useState } from 'react';
import Button, { Appearance } from '@atlaskit/button';

import {
  Spotlight,
  SpotlightManager,
  SpotlightTarget,
  SpotlightTransition,
} from '@atlaskit/onboarding';

const ModalSubmit = (props: {
  submit: () => void;
  icon: string;
  isDisabled: boolean;
  appearance: Appearance;
  className: string;
  buttonName: string;
  text: string;
}) => {
  const [isSpotlightActive, setIsSpotlightActive] = useState(false);
  const start = () => setIsSpotlightActive(true);
  const end = () => setIsSpotlightActive(false);
  const endWithProps = () => {
    setIsSpotlightActive(false);
    props.submit();
  };

  return (
    <SpotlightManager>
      <SpotlightTarget name="codesandbox">
        <Button
          iconAfter={props.icon || <></>}
          isDisabled={props.isDisabled}
          appearance={props.appearance || 'primary'}
          className={props.className}
          onClick={() => start()}>
          {props.buttonName}
        </Button>
      </SpotlightTarget>
      <SpotlightTransition>
        {isSpotlightActive && (
          <Spotlight
            actions={[
              {
                onClick: () => endWithProps(),
                text: 'Submit',
              },
              {
                onClick: () => end(),
                text: 'Cancel',
              },
            ]}
            // headingAfterElement={
            //     <Button
            //         appearance="primary"
            //         onClick={() => end()}
            //     > X </Button>
            // }
            dialogPlacement={'bottom center'}
            heading={props.text}
            target="codesandbox"
            key="codesandbox"
            targetRadius={3}></Spotlight>
        )}
      </SpotlightTransition>
    </SpotlightManager>
  );
};

export default ModalSubmit;
