import React, { useState } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import Button from '@atlaskit/button';
import '../DashBoard.css';

const JsonTable = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const formatDate = (cell) => {
    return moment(cell.value).format('MMM DD, YYYY HH:mm:ss');
  };

  const showDiff = (cell) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button
          appearance={'primary'}
          type="checkbox"
          onClick={() => {
            props.setDiff(cell.data);
            props.showDiff(true);
          }}>
          Show
        </Button>
      </div>
    );
  };

  return (
    <div className="history-container">
      <div style={{ width: '100%', height: '100%' }}>
        <div
          id="myGrid"
          style={{
            height: '400px',
            width: '100%',
          }}
          className="ag-theme-alpine">
          <AgGridReact
            enableRangeSelection={true}
            pagination={true}
            rowHeight={35}
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              editable: true,
              minWidth: 180,
            }}
            rowData={props.data}
            autoGroupColumnDef={{
              headerName: 'Group',
              minWidth: 250,
              field: 'model',
              rowGroupIndex: 1,
              cellRenderer: 'agGroupCellRenderer',
              cellRendererParams: { checkbox: true },
            }}
            frameworkComponents={{
              format_date: formatDate,
              showDiff: showDiff,
            }}
            onGridReady={onGridReady}></AgGridReact>
        </div>
      </div>
    </div>
  );
};

const columnDefs = [
  {
    headerName: 'Date Created',
    field: 'date',
    cellStyle: { display: 'flex', alignItems: 'center' },
    editable: false,
    cellRenderer: 'format_date',
  },
  {
    headerName: 'Updated By',
    field: 'user_email',
    sort: 'asc',
    cellStyle: { display: 'flex', alignItems: 'center', width: '200px' },
    editable: false,
  },
  {
    headerName: 'Difference',
    cellStyle: { display: 'flex', justifyContent: 'center' },
    editable: false,
    cellRenderer: 'showDiff',
    suppressColumnsToolPanel: true,
  },
];

export default JsonTable;
