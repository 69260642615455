import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../TextEditor/TextEditor';
import Calendar from './DatePicker';
import Button from '@atlaskit/button';
import HtmlViewer from './HtmlViewer';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import ProductSelector from '../../UserSelectorPanel/Selectors/ProductSelector';
import AccountSelector from '../../UserSelectorPanel/Selectors/AccountSelector';
import UserSelector from '../../UserSelectorPanel/Selectors/UserSelector';
import Toggle from '@atlaskit/toggle';
import '../Notification.css';
import { toast } from 'react-toastify';
import ModalSubmit from '../../../utlils/ModalSubmit';
import {
  UpdateNotificationInput,
  useCategoriesQuery,
  useNotificationQuery,
  useUpdateNotificationMutation,
} from '../../../graphql/generated';
import sanitizeHtml from 'sanitize-html';
import Select, { OptionType } from '@atlaskit/select';
import { selectorStyle } from '../../UserSelectorPanel/selectorStyles';

const EditNotificationModal = (props: {
  product: string;
  NotificationId: {
    id: string;
    toProduct: string;
  };
  setIsUpdated: (updated: boolean) => void;
  closeModal: () => void;
}) => {
  const [selectedProduct, setSelectedProduct] = useState<OptionType>({
    value: props.product,
    label: props.product,
  });
  const [notificationInput, setNotificationInput] = useState<UpdateNotificationInput>();
  const [selectedCategory, setSelectedCategory] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: 'Select Category',
  });

  const notificationQuery = useNotificationQuery({
    variables: {
      id: props.NotificationId.id,
    },
  });

  const [updateNotificationMutation] = useUpdateNotificationMutation({
    onError: (error) => {
      console.log(error.message);
    },
  });

  const notification = notificationQuery.data?.notification;
  const categories = useCategoriesQuery().data?.categories;

  useEffect(() => {
    const category = categories?.find((category) => category.id === notificationInput?.category);

    if (category) {
      setSelectedCategory({ label: category.name, value: category.id || '' });
    }
  }, [categories, notificationInput]);

  useEffect(() => {
    if (notification) {
      setNotificationInput({
        ...notification,
        id: notification.id || '',
        category: notification.category.id,
        watchedBy: notification.watchedBy?.map((user) => user.userId),
        destination: {
          toAll: notification.destination.toAll,
          toAccount: notification.destination.toAccount?.map((account) => String(account.id)),
          toUser: notification.destination.toUser?.map((user) => String(user.id)),
        },
      });
    }
  }, [notification]);

  const changeNotification = () => {
    const sanitizedMessage = sanitizeHtml(notificationInput?.message || '', {
      allowedTags: ['<p><br></p>', 'img'],
    });

    if (!notification?.title.length || !notification.message.length || !sanitizedMessage.length) {
      toast.error('Not all fields are filled');
    } else if (notificationInput?.id) {
      delete notificationInput['__typename'];
      void updateNotificationMutation({
        variables: {
          updateNotificationInput: notificationInput,
        },
      });
    }
  };

  const AccountsOnchange = (accounts: OptionType[]) => {
    if (selectedProduct?.value && accounts && notificationInput) {
      const isSelected = accounts.find((account) => account.value === 'All');
      if (isSelected) {
        setNotificationInput({
          ...notificationInput,
          destination: {
            toAll: true,
            toAccount: [],
            toUser: [],
          },
        });
      } else {
        setNotificationInput({
          ...notificationInput,
          destination: {
            toAll: false,
            toAccount: accounts.map((account) => String(account.value)),
            toUser: [],
          },
        });
      }
    }
  };

  const UserOnChange = (users: OptionType[]) => {
    if (users) {
      const isSelected = users.find((user) => user.value === 'All');
      if (isSelected) {
        setNotificationInput({
          ...notificationInput,
          id: notificationInput?.id || '',
          destination: {
            toAccount: notificationInput?.destination?.toAccount,
            toUser: [],
            toAll: notificationInput?.destination?.toAll || false,
          },
        });
      } else {
        setNotificationInput({
          ...notificationInput,
          id: notificationInput?.id || '',
          destination: {
            toAccount: notificationInput?.destination?.toAccount,
            toUser: users.map((user) => String(user.value)),
            toAll: notificationInput?.destination?.toAll || false,
          },
        });
      }
    }
  };

  const onMenuClose = () => {
    notificationInput &&
      setNotificationInput({
        ...notificationInput,
      });
  };

  const setProduct = (value: OptionType) => {
    setSelectedProduct(value);

    notificationInput &&
      setNotificationInput({
        ...notificationInput,
        addonKey: String(value.value),
        destination: { ...notificationInput.destination, toAccount: [], toUser: [], toAll: false },
      });
  };

  const shouldDisplayUsersSelector =
    !notificationInput?.destination?.toAll && notificationInput?.destination?.toAccount?.length;

  return (
    <ModalTransition>
      <Modal width={1200}>
        <ModalHeader>
          <ModalTitle> {'Edit Notification'} </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="notification-inner-container">
            <div className="notification-selectors-container">
              <div className="notification-select">
                <ProductSelector
                  available={true}
                  setSelectedProduct={setProduct}
                  product={selectedProduct}
                  productOnEdt={selectedProduct?.value}
                />
                {notificationInput && selectedProduct ? (
                  <AccountSelector
                    defaultValue={notification?.destination?.toAccount?.map((account) =>
                      account.id ? account.id : '',
                    )}
                    toAll={
                      notification?.destination?.toAll ||
                      notificationInput?.destination?.toAll ||
                      false
                    }
                    AccountsOnChange={AccountsOnchange}
                    product={selectedProduct}
                    onMenuClose={onMenuClose}
                  />
                ) : (
                  <></>
                )}
                {shouldDisplayUsersSelector && selectedProduct ? (
                  <UserSelector
                    defaultValue={notification?.destination?.toUser?.map((user) =>
                      user.id ? user.id : '',
                    )}
                    selectedValues={{
                      accounts: notificationInput?.destination?.toAccount || [],
                    }}
                    UserOnChange={UserOnChange}
                    product={String(selectedProduct?.value)}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="title-container">
                <div className="date-picker">
                  <Calendar
                    createDate={(date: string) =>
                      notificationInput &&
                      setNotificationInput({
                        ...notificationInput,
                        publicationDate: new Date(date).getTime(),
                      })
                    }
                    date={notificationInput?.publicationDate}
                  />
                </div>
                <label id="toggle-to-al-label">
                  <Toggle
                    id="toggle-to-all"
                    isChecked={notificationInput?.hideFromNewUsers || false}
                    size="large"
                    onChange={(e) =>
                      notificationInput &&
                      setNotificationInput({
                        ...notificationInput,
                        hideFromNewUsers: e.target.checked,
                      })
                    }
                    label="Hide from new users"
                  />
                  <p> Hide for new users </p>
                </label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                  <label id="title">
                    <p>
                      Title<span className="required">*</span>
                    </p>
                    <input
                      id="title-text"
                      maxLength={256}
                      placeholder="Text"
                      name="title"
                      value={notificationInput?.title || ''}
                      onChange={(e) =>
                        notificationInput &&
                        setNotificationInput({
                          ...notificationInput,
                          title: e.target.value,
                        })
                      }
                    />
                  </label>
                  <label id="category" style={{ marginLeft: '10px' }}>
                    <p>
                      Category<span className="required">*</span>
                    </p>
                    <Select
                      className="select-category-container"
                      inputId="grouped-options-example"
                      value={selectedCategory}
                      styles={selectorStyle}
                      options={categories?.map((category) => {
                        return { value: category.id, label: category.name };
                      })}
                      onChange={(category) => {
                        setNotificationInput({
                          ...notificationInput,
                          id: notificationInput?.id || '',
                          category: category?.value || '',
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
              {notificationInput ? (
                <TextEditor
                  text={notificationInput.message || ''}
                  maxLength={1000}
                  createText={(message: string) =>
                    setNotificationInput({
                      ...notificationInput,
                      message: message,
                    })
                  }
                />
              ) : null}
            </div>
            {notificationInput ? (
              <div className="html-previewer-container">
                <div className="html-previewer-inter">
                  <HtmlViewer
                    template={`<p><strong class="ql-font-roboto ql-size-large"><span class="ql-cursor">${String(
                      notificationInput.title,
                    )}</span></strong></p>${String(notificationInput.message)}`}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <Button onClick={() => props.closeModal()} autoFocus>
              {' '}
              Close{' '}
            </Button>
            <ModalSubmit
              buttonName={'Save'}
              submit={() => {
                changeNotification();
                props.closeModal();
              }}
              className={'table-preview-button'}
              text={'Save Notification?'}
              icon={'default'}
              isDisabled={false}
              appearance={'link'}
            />
          </>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default EditNotificationModal;
