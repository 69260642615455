import { EmptyState } from '../../images';
import styles from './customEmptyState.module.css';

const CustomEmptyState = ({ message }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          width: '100%',
        }}>
        <div className={message ? styles.emptyStateImage : ''}>{EmptyState}</div>
        <p style={{ color: '#828282', fontSize: '20px' }}>{message}</p>
      </div>
    </>
  );
};

export default CustomEmptyState;
