import { AxiosResponse } from 'axios';
import axios from '../axiosClient';
import moment from 'moment';

export function getNotifications(product, page) {
  return axios
    .get(`/api/notifications?product=${product}&page=${page}`)
    .then((response) => {
      return response.data.map((notification) => {
        return {
          changeNotification: notification.changeNotification,
          date: moment(notification.date).format('MMM DD, YYYY HH:mm:ss'),
          id: notification.id,
          message: notification.message,
          publicationDate: moment(notification.publicationDate).format('MMM DD, YYYY HH:mm:ss'),
          title: notification.title.replace(/<\/?[^>]+(>|$)/g, '') || notification.title,
          toAccount: notification.toAccount,
          toAccountText: notification.toAccountText,
          toAll:
            (!notification.toUser && !notification.toAccount) || notification.toAll ? 'Yes' : 'No',
          toProduct: product,
          toUser: notification.toUser,
          toUserText: notification.toUserText,
          whoIsRead: notification.whoIsRead,
          _id: notification._id,
        };
      });
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function getAvailableNotifications() {
  return axios
    .get('/api/notifications/available')
    .then((response: AxiosResponse<string[]>) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function removeNotification(id, DB) {
  return axios
    .delete(
      '/api/notifications',
      {
        data: {
          id,
          DB,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function getNotificationById(NotificationId, selectedNotification) {
  return axios
    .get(`/api/notifications/id?id=${NotificationId}&selectedNotification=${selectedNotification}`)
    .then((response) => {
      return response.data;
    });
}

export function PutNotification(notificationToEdit, product) {
  return axios
    .post(
      `/api/notifications?product=${product}`,
      {
        ...notificationToEdit,
        title: `<span style="font-size: 16px; font-weight:600 ">${notificationToEdit.title}</span>`,
        date: new Date().toISOString(),
        toAccount: notificationToEdit.toAccount.map((account) => account.value),
        toAccountText: notificationToEdit.toAccount.map((account) => account.fullUrl),
        toUser: notificationToEdit.toUser.map((user) => user.value),
        toUserText: notificationToEdit.toUser.map((user) => user.label),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .catch((error) => {
      console.log(error);
    });
}

export function ChangeNotification(notificationToEdit, product) {
  return axios
    .post(
      `/api/notifications/change?product=${product}`,
      {
        id: notificationToEdit._id,
        message: notificationToEdit.message,
        onlyToCurrentDate: notificationToEdit.onlyToCurrentDate,
        publicationDate: notificationToEdit.publicationDate,
        title: `<span style="font-size: 16px; font-weight:600 ; font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif" > ${notificationToEdit.title} </span>`,
        toAll: notificationToEdit.toAll,
        date: new Date().toISOString(),
        toAccount: notificationToEdit.toAccount.map((account) =>
          account.value ? account.value : account,
        ),
        toAccountText:
          notificationToEdit.toAccount.map((account) => account.value)[0] !== undefined ||
          notificationToEdit.toAccount.map((user) => user.value).length === 0
            ? notificationToEdit.toAccount.map((account) =>
                account.fullUrl ? account.fullUrl : account,
              )
            : notificationToEdit.toAccountText,
        toUser: notificationToEdit.toUser.map((user) => (user.value ? user.value : user)),
        toUserText:
          notificationToEdit.toUser.map((user) => user.value)[0] !== undefined ||
          notificationToEdit.toUser.map((user) => user.value).length === 0
            ? notificationToEdit.toUser.map((user) => (user.label ? user.label : user))
            : notificationToEdit.toUserText,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .catch((error) => {
      console.log(error);
    });
}
