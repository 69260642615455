import { useEffect, useState } from 'react';
import getAccounts from '../../../api/host/accounts';
import { CheckboxSelect, OptionsType, OptionType } from '@atlaskit/select';
import {
  AccountModel,
  useAccountByIdLazyQuery,
  useCreateAccountMutation,
} from '../../../graphql/generated';
import { getShortUrl } from '../../../utlils';

const AccountSelector = ({
  defaultValue,
  onMenuClose,
  AccountsOnChange,
  toAll,
  product,
}: {
  defaultValue?: string[];
  onMenuClose: () => void;
  AccountsOnChange: (accounts: OptionType[]) => void;
  toAll: boolean;
  product?: OptionType;
}) => {
  const [accounts, setAccounts] = useState<AccountModel[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<OptionType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [getAccountQuery] = useAccountByIdLazyQuery({
    onError: (error) => {
      console.log(error);
    },
  });
  const [createAccountMutation] = useCreateAccountMutation({
    onError: () => {},
  });

  function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout;
    return (...args: Params) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }

  const setSelected = (accounts: OptionType[]) => {
    setSelectedAccounts(accounts);
    AccountsOnChange(accounts);
    !accounts.some((account) => account.value === 'All');
  };

  const options = (accounts: AccountModel[]) => {
    return [
      { value: 'All', label: 'All' },
      ...accounts
        .map((account) => ({
          value: account?.id || '',
          label: getShortUrl(account?.baseUrl),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  };

  const setAccountsList = async () => {
    if (product) {
      setIsLoading(true);
      try {
        const response = await getAccounts(String(product.value), '', searchQuery);
        const result = response.filter((account) => account.installed.clientKey !== null);

        const accounts = await Promise.all([
          ...result.map(async (account) => {
            return (
              await createAccountMutation({
                variables: {
                  createAccountInput: {
                    clientKey: account.installed.clientKey,
                    baseUrl: account.installed.baseUrl,
                  },
                },
              })
            ).data?.createAccount;
          }),
          ...(defaultValue?.map(async (id) => {
            return (
              await getAccountQuery({
                variables: {
                  id,
                },
              })
            ).data?.accountById;
          }) || []),
        ]);
        const uniqueAccounts = accounts.filter((account, index) => {
          return (
            index ===
            accounts.findIndex((accountToFind) => {
              return accountToFind?.baseUrl === account?.baseUrl;
            })
          );
        });
        setAccounts(uniqueAccounts.map((account) => account as AccountModel));
        setIsLoading(false);
      } catch (error) {
        setAccounts([]);
        setIsLoading(false);
      }
    } else {
      setAccounts([]);
      setIsLoading(false);
    }
  };

  const accountOnChange = (newlySelectedAccounts: OptionsType<OptionType>) => {
    const isAll = selectedAccounts.filter((account) => account.value === 'All');
    const isAllPressed = newlySelectedAccounts.filter((account) => account.value === 'All');
    if (!isAll.length && isAllPressed.length) {
      setSelected(isAllPressed);
    } else if (newlySelectedAccounts.length > 0) {
      setSelected(
        newlySelectedAccounts
          .filter((account) => account.value !== 'All')
          .map((account) => ({ label: account.label, value: account.value })),
      );
    } else {
      setSelected([{ label: 'All', value: 'All' }]);
    }
  };

  useEffect(() => {
    void setAccountsList();
  }, []);

  useEffect(() => {
    if (toAll) {
      setSelected([
        {
          value: 'All',
          label: 'All',
        },
      ]);
    } else {
      setSelected(
        accounts
          .filter((account) => defaultValue?.includes(account?.id || ''))
          .map((account) => ({ label: getShortUrl(account?.baseUrl), value: account?.id || '' })),
      );
    }
  }, [accounts]);

  useEffect(() => {
    if (searchQuery !== '') {
      void setAccountsList();
    }
  }, [product, searchQuery]);

  return (
    <>
      {product?.value ? (
        <CheckboxSelect
          isLoading={isLoading}
          inputId="checkbox-select-example"
          className="multi-select-container"
          classNamePrefix="select"
          onChange={(accounts) => accountOnChange(accounts)}
          onMenuClose={() => onMenuClose()}
          value={selectedAccounts}
          onInputChange={debounce((inputValue) => setSearchQuery(inputValue), 1000)}
          options={options(accounts)}
          placeholder="To Account"
        />
      ) : (
        <> </>
      )}
    </>
  );
};

export default AccountSelector;
