import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

const Difference = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      <ReactDiffViewer
        oldValue={JSON.stringify(props.diffText.params.previous, null, 4)}
        newValue={JSON.stringify(props.diffText.params.current, null, 4)}
        showDiffOnly={props.expanded}
        compareMethod={DiffMethod.LINES}
        splitView={props.expanded}
      />
    </div>
  );
};

export default Difference;
