import axios from '../axiosClient';

export default function getQueries() {
  return axios.get('/api/query').then((response) => {
    return response.data;
  });
}

export function changeQuery(value) {
  return axios.post(
    '/api/query/change',
    {
      current: value.newValue,
      previous: value.data.date,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}

export function putQuery(queries, user) {
  return axios
    .put(
      '/api/query',
      {
        name: 'none',
        query: queries,
        user_name: user.name,
        date: new Date(),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      // createLog(user,"pivots","created query")
      return response.data;
    });
}

export function removeQuery(query) {
  return axios
    .delete(
      '/api/query',
      {
        data: { query: query },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    });
}
