import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../TextEditor/TextEditor';
import Calendar from './DatePicker';
import Button from '@atlaskit/button';
import HtmlViewer from './HtmlViewer';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import ProductSelector from '../../UserSelectorPanel/Selectors/ProductSelector';
import AccountSelector from '../../UserSelectorPanel/Selectors/AccountSelector';
import UserSelector from '../../UserSelectorPanel/Selectors/UserSelector';
import Toggle from '@atlaskit/toggle';
import '../Notification.css';
import { created_notification } from '../../../utlils/logsMessages';
import { createLog } from '../../../api/host/internal-logs';
import { toast } from 'react-toastify';
import ModalSubmit from '../../../utlils/ModalSubmit';
import {
  CreateNotificationInput,
  useCategoriesQuery,
  useCreateNotificationMutation,
} from '../../../graphql/generated';
import sanitizeHtml from 'sanitize-html';
import Select, { OptionType } from '@atlaskit/select';
import moment from 'moment';
import { selectorStyle } from '../../UserSelectorPanel/selectorStyles';

const initialNotification = {
  message: '',
  hideFromNewUsers: false,
  publicationDate: moment.now(),
  dateOfCreation: moment.now(),
  title: '',
  addonKey: 'TIS',
  category: '',
  destination: {
    toAccount: [],
    toUser: [],
    toAll: true,
  },
};

const NotificationModal = (props: {
  product: string;
  setIsUpdated: (updated: boolean) => void;
  closeModal: () => void;
}) => {
  const [selectedProduct, setSelectedProduct] = useState<{ value: string; label: string }>({
    value: props.product,
    label: props.product,
  });
  const [notification, setNotification] = useState<CreateNotificationInput>(() => ({
    ...initialNotification,
    addonKey: props.product,
  }));
  const [createNotificationMutation] = useCreateNotificationMutation({
    onError: (error) => {
      console.log(error.message);
    },
  });
  const categories = useCategoriesQuery().data?.categories;

  useEffect(() => {
    if (notification.category === '' && categories) {
      setNotification({
        ...notification,
        category: categories[0].id || '',
      });
    }
  }, [notification]);

  useEffect(() => {
    if (props.product !== selectedProduct.value) {
      setSelectedProduct({
        value: props.product,
        label: props.product,
      });
    }
  }, [props.product]);

  const createNotification = async () => {
    const sanitizedMessage = sanitizeHtml(notification?.message || '', {
      allowedTags: ['<p><br></p>', 'img'],
    });

    if (!notification?.title.length || !notification?.message.length || !sanitizedMessage.length) {
      toast.error('Not all fields are filled');
    } else {
      await createNotificationMutation({
        variables: {
          createNotificationInput: notification,
        },
      });
      if (selectedProduct?.value === props.product) {
        props.setIsUpdated(true);
      }
      props.closeModal();
      await createLog(created_notification, 200);
      toast.success('Notification created successfully!');
    }
  };

  const AccountsOnChange = (accounts: OptionType[]) => {
    if (selectedProduct?.value && accounts && notification) {
      const isSelected = accounts?.map((account) => account.value === 'All').pop();
      if (isSelected) {
        setNotification({
          ...notification,
          destination: {
            toAll: true,
            toAccount: [],
            toUser: [],
          },
        });
      } else {
        setNotification({
          ...notification,
          destination: {
            toAll: false,
            toAccount: accounts.map((account) => String(account.value)),
            toUser: [],
          },
        });
      }
    }
  };

  const UserOnChange = (users: OptionType[]) => {
    if (users) {
      const isSelected = users?.map((user) => user.value === 'All').pop();
      if (isSelected) {
        setNotification({
          ...notification,
          destination: {
            toAll: notification.destination.toAll,
            toAccount: notification.destination.toAccount,
            toUser: [],
          },
        });
      } else {
        setNotification({
          ...notification,
          destination: {
            toAll: notification.destination.toAll,
            toAccount: notification.destination.toAccount,
            toUser: users.map((user) => String(user.value)),
          },
        });
      }
    }
  };

  const onMenuClose = () => {
    setNotification({
      ...notification,
      destination: { ...notification.destination, toAccount: notification.destination.toAccount },
    });
  };

  const setProduct = (value: { label: string; value: string }) => {
    setSelectedProduct(value);
    notification &&
      setNotification({
        ...notification,
        destination: { ...notification.destination, toAccount: [], toUser: [] },
        addonKey: value.value,
      });
  };

  return (
    <ModalTransition>
      <Modal width={1200}>
        <ModalHeader>
          <ModalTitle> {'Create Notification'} </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="notification-inner-container">
            <div className="notification-selectors-container">
              <div className="notification-select">
                <ProductSelector
                  available={true}
                  setSelectedProduct={setProduct}
                  product={selectedProduct}
                  productOnEdt={selectedProduct?.value}
                  isDisabled={false}
                />
                {notification ? (
                  <AccountSelector
                    toAll={notification?.destination.toAll}
                    AccountsOnChange={AccountsOnChange}
                    product={selectedProduct}
                    onMenuClose={onMenuClose}
                  />
                ) : (
                  <></>
                )}
                {!notification?.destination.toAll && notification.destination.toAccount?.length ? (
                  <UserSelector
                    selectedValues={{
                      accounts: notification?.destination.toAccount || [],
                    }}
                    UserOnChange={UserOnChange}
                    product={selectedProduct?.value}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="title-container">
                <div className="date-picker">
                  <Calendar
                    createDate={(date: string) =>
                      notification &&
                      setNotification({
                        ...notification,
                        publicationDate: Number(date),
                      })
                    }
                    date={notification?.publicationDate}
                  />
                </div>
                <label id="toggle-to-al-label">
                  <Toggle
                    id="toggle-to-all"
                    isChecked={notification?.hideFromNewUsers}
                    size="large"
                    onChange={(e) =>
                      notification &&
                      setNotification({
                        ...notification,
                        hideFromNewUsers: e.target.checked,
                      })
                    }
                    label="Hide from new users"
                  />
                  <p> Hide for new users </p>
                </label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                  <label id="title" style={{ flex: 1 }}>
                    <p>
                      Title<span className="required">*</span>
                    </p>
                    <input
                      id="title-text"
                      placeholder="Text"
                      name="title"
                      maxLength={256}
                      value={notification?.title}
                      onChange={(e) =>
                        notification &&
                        setNotification({
                          ...notification,
                          title: e.target.value,
                        })
                      }
                    />
                  </label>
                  <label id="category" style={{ marginLeft: '10px' }}>
                    <p>
                      Category<span className="required">*</span>
                    </p>
                    {categories && (
                      <Select
                        className="select-category-container"
                        inputId="grouped-options-example"
                        styles={selectorStyle}
                        value={{
                          value: notification.category,
                          label:
                            categories.find((category) => category.id === notification.category)
                              ?.name || '',
                        }}
                        options={categories?.map((category) => {
                          return { value: category.id || '', label: category.name };
                        })}
                        onChange={(category) => {
                          setNotification({
                            ...notification,
                            category: category?.value || '',
                          });
                        }}
                      />
                    )}
                  </label>
                </div>
              </div>
              <TextEditor
                maxLength={1000}
                createText={(message: string) =>
                  notification &&
                  setNotification({
                    ...notification,
                    message: message,
                  })
                }
              />
            </div>
            {notification ? (
              <div className="html-previewer-container">
                <div className="html-previewer-inter">
                  <HtmlViewer
                    template={`<p><strong class="ql-font-roboto ql-size-large"><span class="ql-cursor">${String(
                      notification.title,
                    )}</span></strong></p>${String(notification.message)}`}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <Button onClick={() => props.closeModal()} autoFocus>
              {' '}
              Close{' '}
            </Button>
            <ModalSubmit
              buttonName={'Create Notification'}
              submit={() => void createNotification()}
              className={'table-preview-button'}
              text={'Create Notification?'}
              appearance={'default'}
              icon={'default'}
              isDisabled={false}
            />
          </>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
};

export default NotificationModal;
