import React, { useEffect, useState } from 'react';
import '../DashBoard.css';
import { connect } from 'react-redux';
import { getUserFullInfo } from '../../../api/host/users';
import moment from 'moment';
import { Accordion } from 'react-bootstrap';
import { EmptyState } from '../../../images';
import Tooltip from '@atlaskit/tooltip';
import Spinner from '@atlaskit/spinner';
import CustomEmptyState from '../../helpers/CustomEmptyState';

const UserPanel = (props) => {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const locale = require('locale-codes');

  useEffect(() => {
    setUser(null);
    if (props.selectedUser) {
      setIsLoading(true);
      getUserFullInfo(
        props.selectedAccount?.installed?.clientKey,
        props.selectedUser.self,
        props.product.value,
      ).then((response) => {
        setUser(response);
        setIsLoading(false);
      });
    }
  }, [props.selectedUser]);

  return (
    <>
      <Accordion defaultActiveKey="0" className="dashboard-one">
        <Accordion.Item eventKey="0">
          <Accordion.Header>User Panel</Accordion.Header>
          <Accordion.Body>
            {isLoading ? (
              <div className="empty-state">
                <Spinner size={50} />
              </div>
            ) : (
              <>
                {user && props.selectedUser ? (
                  <>
                    {Object.keys(user).length !== 0 && (
                      <>
                        {Object.keys(user).length !== 0 ? (
                          <div className="user-inner-container">
                            <div className="user-profile-container">
                              <div className="user-info-container">
                                <div className="user-avatar-container">
                                  <img id="avatar" src={user.avatarUrls['48x48']} alt="" />
                                  <Tooltip
                                    content={
                                      user.displayName.length < 30 ? undefined : user.displayName
                                    }>
                                    <p id="name">
                                      {' '}
                                      {user.displayName.length < 30
                                        ? user.displayName
                                        : user.displayName.substring(0, 20) + '...'}{' '}
                                    </p>
                                  </Tooltip>
                                </div>
                                <div className="activity-container">
                                  <div className="activity-text-container">
                                    <p>Timezone </p>
                                    <p> Language </p>
                                    <p> Last Visit </p>
                                    <p> Last Action </p>
                                  </div>
                                  <div className="activity-time-container">
                                    <p>
                                      {' '}
                                      {user.timeZone === 'Europe/Kiev'
                                        ? 'Europe/Kyiv'
                                        : user.timeZone}{' '}
                                    </p>
                                    <p> {locale.getByTag(user.locale.replace('_', '-')).name} </p>
                                    <p>
                                      {' '}
                                      {moment(props.userJson?.activity?.lastActive).format(
                                        'MMM DD, YYYY HH:mm:ss',
                                      )}{' '}
                                    </p>
                                    <p>
                                      {' '}
                                      {moment(props.userJson?.activity?.lastActivity).format(
                                        'MMM DD, YYYY HH:mm:ss',
                                      )}{' '}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="roles-container">
                                <div className="groups-container">
                                  <p id="roles-text"> Application Roles </p>
                                  <div className="badge-container">
                                    {Object.values(user.applicationRoles.items).map((e) =>
                                      Object.values(user.applicationRoles.items) ? (
                                        <p id="badge" key={e.name}>
                                          {' '}
                                          {e.name}{' '}
                                        </p>
                                      ) : (
                                        { EmptyState }
                                      ),
                                    )}
                                  </div>
                                </div>
                                <div className="groups-container">
                                  <p id="roles-text"> Groups </p>
                                  <div className="badge-container">
                                    {Object.values(user.groups.items).map((e) => (
                                      <p id="badge" key={e.name}>
                                        {' '}
                                        {e.name}{' '}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="empty-state"> {EmptyState} </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <CustomEmptyState message={'No user selected'} />
                )}
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedUser: state.dashboard.selectedUser,
    users: state.dashboard.users,
    product: state.dashboard.product,
    userJson: state.dashboard.userJson,
  };
}

export default connect(mapStateToProps)(UserPanel);
