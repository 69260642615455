import {
  SET_PRODUCT,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_USER,
  SET_USER_JSON,
  SET_JIRA_JSON,
  SET_UPDATE_JSON_EDITOR,
  SET_QUERY_USER,
} from '../types/dash-board-types';

export const setSelectedAccount = (payload) => ({ type: SET_SELECTED_ACCOUNT, payload: payload });
export const setUserJson = (payload) => ({ type: SET_USER_JSON, payload: payload });
export const setProduct = (payload) => ({ type: SET_PRODUCT, payload: payload });
export const setSelectedUser = (payload) => ({ type: SET_SELECTED_USER, payload: payload });
export const setSelectedJiraJSON = (payload) => ({ type: SET_JIRA_JSON, payload: payload });
export const setUpdateJsonEditor = (payload) => ({
  type: SET_UPDATE_JSON_EDITOR,
  payload: payload,
});
export const setQueryUser = (payload) => ({ type: SET_QUERY_USER, payload: payload });
