import React, { useState } from 'react';
import '../Notification.css';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DatePicker = ({ createDate, date }) => {
  const [typedDateForValidation, setTypedDateForValidation] = useState('');

  const handleSetDate = (value) => {
    if (!value.length) return;

    const date = moment(value);
    if (!date.isValid()) {
      toast.error('Not Valid Date!');
      setTypedDateForValidation('');
      return;
    }
    setTypedDateForValidation('');
    createDate(date);
  };

  const handleSetTime = (value) => {
    if (!value.length) return;
    const date = moment(value, 'HH:mm', true);
    if (!date.isValid()) {
      toast.error('Not Valid Time!');
      return;
    }
    return new Date(date.toDate());
  };

  return (
    <>
      <label htmlFor={'toggle-date-picker'}>
        Publication Date<span className="required">*</span>
      </label>
      <>
        <DateTimePicker
          timeFormat="HH:mm"
          timeIsEditable
          timePickerProps={{
            placeholder: '12:00',
            parseInputValue: (value) => handleSetTime(value),
          }}
          datePickerProps={{
            parseInputValue: (value) => {
              setTypedDateForValidation(value);
            },
          }}
          value={moment(date).format('YYYY-MM-DD HH:mm')}
          defaultValue={moment().format('YYYY-MM-DD HH:mm')}
          datePickerSelectProps={{ inputId: 'toggle-date-picker' }}
          onChange={(value) => {
            handleSetDate(typedDateForValidation.length ? typedDateForValidation : value);
          }}
        />
      </>
    </>
  );
};

export default DatePicker;
