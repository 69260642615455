import React, { useState, useRef, useCallback } from 'react';
import '../Pivot.css';
import Button from '@atlaskit/button';
import { putQuery } from '../../../api/host/query';
import { connect } from 'react-redux';
import Tooltip from '@atlaskit/tooltip';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import QueryTable from './QueryTable';
import { applied_query, created_query, searched_by_query } from '../../../utlils/logsMessages';
import { createLog } from '../../../api/host/internal-logs';
import { toast } from 'react-toastify';

const QueryList = (props) => {
  const tagInput = useRef(HTMLInputElement);
  const [queries, setQueries] = useState([]);
  const [queryInput, setQueryInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const handleCreateQuery = () => {
    if (queryInput.length > 0) {
      putQuery(queryInput, props.user)
        .then((response) => {
          setQueries(response);
          createLog(created_query, 200, queryInput);
          toast.success('Query preset created successfully!');
        })
        .then(() => {
          setQueryInput('');
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error('You can`t create empty preset!');
    }
  };

  const onClose = () => {
    closeModal();
  };

  const setSelected = (value) => {
    setQueryInput(value);
    createLog(applied_query, 200, value);
    toast.success('Query preset applied successfully!');
  };

  const setAllQueries = (value) => {
    setQueries(value);
  };

  const handleSearch = () => {
    props.updatePivot(queryInput);
    createLog(searched_by_query, 200, queryInput);
  };

  return (
    <>
      <div className="input-container">
        <input
          placeholder="Add query"
          className="waypoints"
          type="text"
          value={queryInput}
          ref={tagInput}
          onChange={(e) => {
            setQueryInput(e.target.value);
          }}
        />
        <Button
          appearance="primary"
          className="button-submit"
          onClick={() => {
            handleSearch();
          }}>
          Search
        </Button>
        <Button
          appearance="primary"
          className="button-submit"
          onClick={() => {
            handleCreateQuery();
          }}>
          Create
        </Button>
        <Button
          appearance="primary"
          className="button-submit"
          onClick={() => {
            setIsOpen(true);
          }}>
          Presets
        </Button>
        <Tooltip content={'Visit datadog query syntax to learn how to build query params'}>
          <a
            id="link"
            href={'https://docs.datadoghq.com/tracing/trace_explorer/query_syntax/'}
            target="_blank"
            rel="noreferrer">
            <svg
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="30px"
              height="30px">
              {' '}
              <path d="M25,2C12.297,2,2,12.297,2,25s10.297,23,23,23s23-10.297,23-23S37.703,2,25,2z M25,11c1.657,0,3,1.343,3,3s-1.343,3-3,3 s-3-1.343-3-3S23.343,11,25,11z M29,38h-2h-4h-2v-2h2V23h-2v-2h2h4v2v13h2V38z" />
            </svg>
          </a>
        </Tooltip>
      </div>
      <div className="wrap_container">
        <ModalTransition className="modal-container">
          {isOpen && (
            <Modal width={'x-large'} onClose={closeModal}>
              <ModalHeader>
                <ModalTitle>Presets</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <QueryTable
                  queries={queries}
                  setSelected={setSelected}
                  setAllQueries={setAllQueries}
                  onClose={onClose}
                  updatePivot={props.updatePivot}
                />
              </ModalBody>
              <ModalFooter>
                <Button appearance="primary" onClick={closeModal} autoFocus>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ModalTransition>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.main.user,
  };
}

export default connect(mapStateToProps)(QueryList);
