import React, { useEffect, useState } from 'react';
import './App.css';
import { AxiosRequestConfig } from 'axios';
import axios from './api/axiosClient';
import LoginPage from './components/LoginPage/LoginPage';
import Main from './components/Main/Main';
import { connect } from 'react-redux';
import { setUser } from './redux/actions/main-actions';
import Loading from './utlils/Loading';

import { logged_in, logout } from './utlils/logsMessages';
import { createLog } from './api/host/internal-logs';
import { ToastContainer } from 'react-toastify';
import { LicenseManager } from 'ag-grid-enterprise';
import { UserType } from './types/UserType';

const App = (props: { dispatchUser: (user: UserType) => void }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  LicenseManager.prototype.isDisplayWatermark = () => {
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1500);
  }, []);

  async function responseGoogle(googleResponse: { access_token: string }) {
    axios.interceptors.request.use((req: AxiosRequestConfig) => {
      if (req.headers) {
        req.headers.token = googleResponse.access_token;
      }
      return req;
    });
    const response = await axios.post<UserType>('/api/login');
    setIsLoggedIn(true);
    await createLog(logged_in, 200);
    props.dispatchUser(response.data);
  }

  const Logout = () => {
    setIsLoggedIn(false);
    void createLog(logout, 200);
  };

  return (
    <>
      {isLoaded ? (
        <>
          {!isLoggedIn ? (
            <LoginPage responseGoogle={responseGoogle} />
          ) : (
            <>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Main Logout={Logout} />
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapDispatchToProps = {
  dispatchUser: setUser,
};

export default connect(undefined, mapDispatchToProps)(App);
