import React, { useEffect, useState } from 'react';
import './SideBar.css';
import { history } from '../../history';
import {
  dashboardLogo,
  logSystemLogo,
  NotificationLogo,
  PivotLogo,
  playGroundLogo,
  UserManagerLogo,
} from '../../images';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { connect } from 'react-redux';
import Tooltip from '@atlaskit/tooltip';
import { setComponent } from '../../redux/actions/main-actions';
import { switched_tabs } from '../../utlils/logsMessages';
import { createLog } from '../../api/host/internal-logs';
import permissions from '../../services/Permissions';

const SideBar = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [permission, setPermission] = useState({});

  useEffect(() => {
    permissions.checkPermissions('logs-system').then((permission) => {
      setPermission(permission);
    });
  }, []);

  const handleSetComponent = (value) => {
    props.dispatchComponent(value);
    createLog(switched_tabs, 200);
  };

  const onToggle = (value) => {
    props.expand.setExpanded(value);
    setExpanded(value);
  };

  const test = (props) => {
    return (
      <button {...props}>
        <svg
          className="toggle"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          enableBackground="new 0 0 50 50">
          <path
            fill="#42526E"
            d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z"
          />
          <path
            fill="#42526E"
            d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z"
          />
          <path
            fill="#42526E"
            d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z"
          />
        </svg>
      </button>
    );
  };

  return (
    <SideNav
      className="sidebar"
      style={{
        width: expanded ? '210px' : '44px',
        position: props.component === '/' ? 'absolute' : 'initial',
      }}
      onToggle={onToggle}>
      <SideNav.Toggle componentClass={test} />
      <SideNav.Nav className="dashboard">
        {props.sections.dashboard ? (
          <NavItem
            className="test"
            style={{ backgroundColor: props.component === '/' ? '#DEEBFF' : 'transparent' }}
            eventKey="dashboard"
            onClick={() => {
              handleSetComponent('/');
              history.push('/');
            }}>
            <NavIcon>
              <Tooltip content={!expanded ? 'Dashboard' : undefined}>{dashboardLogo}</Tooltip>
            </NavIcon>
            <NavText>
              <a id="menu-text-item"> Dashboard </a>
            </NavText>
          </NavItem>
        ) : (
          <></>
        )}
        {/*{props.sections.pivots ?*/}
        {/*    <NavItem*/}
        {/*        style={{ backgroundColor: props.component === '/pivot' ? "#DEEBFF" : "transparent" }}*/}
        {/*        eventKey="pivots"*/}
        {/*        onClick={() => {*/}
        {/*            handleSetComponent('/pivot')*/}
        {/*            history.push('/pivot')*/}
        {/*        }}>*/}
        {/*        <NavIcon>*/}
        {/*            <Tooltip content={!expanded ? 'Pivot' : undefined}>*/}
        {/*                {PivotLogo}*/}
        {/*            </Tooltip>*/}
        {/*        </NavIcon>*/}
        {/*        <NavText>*/}
        {/*            <a id="menu-text-item"> Pivots </a>*/}
        {/*        </NavText>*/}
        {/*    </NavItem> : <></>}*/}
        {props.sections.notifications ? (
          <NavItem
            style={{
              backgroundColor: props.component === '/notifications' ? '#DEEBFF' : 'transparent',
            }}
            eventKey="notifications"
            onClick={() => {
              handleSetComponent('/notifications');
              history.push('/notifications');
            }}>
            <NavIcon>
              <Tooltip content={!expanded ? 'Notifications' : undefined}>
                {NotificationLogo}
              </Tooltip>
            </NavIcon>
            <NavText>
              <a id="menu-text-item"> Notifications </a>
            </NavText>
          </NavItem>
        ) : (
          <></>
        )}
        {props.sections['user-manager'] ? (
          <NavItem
            style={{
              backgroundColor: props.component === '/usermanager' ? '#DEEBFF' : 'transparent',
            }}
            eventKey="user-manager"
            onClick={() => {
              handleSetComponent('/usermanager');
              history.push('/usermanager');
            }}>
            <NavIcon>
              <Tooltip content={!expanded ? 'User Manager' : undefined}>{UserManagerLogo}</Tooltip>
            </NavIcon>
            <NavText>
              <a id="menu-text-item"> User Manager</a>
            </NavText>
          </NavItem>
        ) : (
          <></>
        )}
        {permission.read ? (
          <NavItem
            style={{
              backgroundColor: props.component === '/internalLogs' ? '#DEEBFF' : 'transparent',
            }}
            eventKey="user-manager"
            onClick={() => {
              handleSetComponent('/internalLogs');
              history.push('/internalLogs');
            }}>
            <NavIcon>
              <Tooltip content={!expanded ? 'Internal Logs' : undefined}>{logSystemLogo}</Tooltip>
            </NavIcon>
            <NavText>
              <a id="menu-text-item"> Internal Logs</a>
            </NavText>
          </NavItem>
        ) : (
          <></>
        )}
        {permission.read ? (
          <NavItem
            style={{
              backgroundColor: props.component === '/playground' ? '#DEEBFF' : 'transparent',
            }}
            eventKey="playground"
            onClick={() => {
              handleSetComponent('/playground');
              history.push('/playground');
            }}>
            <NavIcon>
              <Tooltip content={!expanded ? 'PlayGround' : undefined}>{playGroundLogo}</Tooltip>
            </NavIcon>
            <NavText>
              <a id="menu-text-item"> PlayGround</a>
            </NavText>
          </NavItem>
        ) : (
          <></>
        )}
      </SideNav.Nav>
    </SideNav>
  );
};

function mapStateToProps(state) {
  return {
    user: state.main.user,
    component: state.main.component,
  };
}

const mapDispatchToProps = {
  dispatchComponent: setComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
