import React, { useEffect, useState } from 'react';
import { getAddons } from '../../../api/host/addons';
import Select from '@atlaskit/select';
import { selectorStyle } from '../selectorStyles';
import { getAvailableNotifications } from '../../../api/host/notifications';
import { setProduct } from '../../../redux/actions/dash-board-actions';
import { connect } from 'react-redux';

const ProductSelector = (props) => {
  const [products, setProducts] = useState({});

  useEffect(() => {
    if (props.available) {
      getAvailableNotifications().then((response) => {
        setProducts(response);
        if (props.productOnEdt) {
          props.dispatchProduct({ value: props.productOnEdt, label: props.productOnEdt });
          props.setSelectedProduct({ value: props.productOnEdt, label: props.productOnEdt });
        } else {
          props.dispatchProduct({ value: response[0], label: response[0] });
          props.setSelectedProduct({ value: response[0], label: response[0] });
        }
      });
    } else {
      getAddons().then((response) => {
        if (!products.value && response[0]) {
          if (!props.product.value) {
            props.setSelectedProduct({ value: response[0], label: response[0] });
          }
        }
        setProducts(response);
      });
    }
  }, []);

  const handleSetProduct = (product) => {
    props.dispatchProduct(product);
    props.setSelectedProduct(product);
  };

  return (
    <>
      {products.length ? (
        <Select
          className="select-container"
          inputId="grouped-options-example"
          onChange={(product) => handleSetProduct(product)}
          styles={selectorStyle}
          isDisabled={props.isDisabled}
          value={{
            value: props.productOnEdt
              ? props.productOnEdt
              : products.find((product) => product === props.product.value),
            label: props.productOnEdt
              ? props.productOnEdt
              : products.find((product) => product === props.product.value),
          }}
          options={products
            .map((product) => {
              return { value: product, label: product };
            })
            .sort((a, b) => a.value.localeCompare(b.value))}
        />
      ) : (
        <></>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    product: state.dashboard.product,
  };
};

const mapDispatchToProps = {
  dispatchProduct: setProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelector);
