import React, { useEffect, useState } from 'react';
import { getAvailableNotifications } from '../../../api/host/notifications';
import '../Notification.css';
import Select from '@atlaskit/select';
import { selectorStyle } from '../../UserSelectorPanel/selectorStyles';

const AvailableNotifications = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    void getAvailableNotifications().then((response) => {
      props.firstProduct(localStorage.getItem('selectedProduct') ?? response[0]);
      setData(response);
    });
  }, []);

  const handleSetSelectedNotifications = (notification) => {
    localStorage.setItem('selectedProduct', notification);
    props.setProduct(notification);
  };

  return (
    <div className="available-notifications">
      {data.length ? (
        <Select
          className="select-container-main"
          styles={selectorStyle}
          inputId="grouped-options-example"
          onChange={(product) => handleSetSelectedNotifications(product.value)}
          value={{
            value:
              data.find((product) => product === props.product) === props.product
                ? props.product
                : handleSetSelectedNotifications(data[0]),
            label:
              data.find((product) => product === props.product) === props.product
                ? props.product
                : handleSetSelectedNotifications(data[0]),
          }}
          options={data
            .map((product) => {
              return { value: product, label: product };
            })
            .sort((a, b) => a.value?.localeCompare(b.value))}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AvailableNotifications;
