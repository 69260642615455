import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Logs.css';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { getInternalLogs } from '../../api/host/internal-logs';

const Logs = () => {
  const [logs, setLogs] = useState(null);
  const gridRef = useRef();

  useEffect(() => {
    getPaginatedLogs().catch(() => {
      setLogs([]);
    });
  }, []);

  async function getPaginatedLogs() {
    let page = 0;
    let resp = {};
    do {
      const response = await getInternalLogs(page);
      if (response.length) {
        gridRef.current.api.applyTransaction({
          add: [...response],
        });
      }
      resp = [...response];
      page++;
    } while (resp.length);
  }

  const formatDate = (cell) => {
    return moment(cell.value).format('MMM DD, YYYY HH:mm:ss');
  };

  const dateComparator = (date1, date2) => {
    return new Date(date1) - new Date(date2);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const colorRenderer = (cell) => {
    let style;
    if (cell.value === 200) {
      style = { color: 'green' };
    } else if (cell.value !== 200) {
      style = { color: 'red' };
    }
    return <span style={style}> {cell.value} </span>;
  };

  return (
    <div style={{ width: '100%', height: '100%', paddingBottom: '5px' }}>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          enableRangeSelection={true}
          pagination={true}
          defaultColDef={{
            flex: 1,
            editable: false,
            sortable: true,
            filter: true,
            minWidth: 230,
          }}
          ref={gridRef}
          animateRows={true}
          rowData={logs}
          sideBar={sideBar}
          autoGroupColumnDef={{
            headerName: 'Group',
            minWidth: 250,
            field: 'model',
            rowGroupIndex: 1,
          }}
          frameworkComponents={{
            format_date: formatDate,
            colorRenderer: colorRenderer,
          }}
          onGridReady={onGridReady}>
          <AgGridColumn
            headerName="Date"
            field="date"
            comparator={dateComparator}
            cellRenderer="format_date"
            suppressColumnsToolPanel={true}
          />
          <AgGridColumn headerName="Message" field="log_msg" />
          <AgGridColumn headerName="User Email" field="user_email" />
          <AgGridColumn headerName="Role" field="user_role" />
          <AgGridColumn headerName="Status" field="status" cellRenderer="colorRenderer" />
        </AgGridReact>
      </div>
    </div>
  );
};
const sideBar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      minWidth: 180,
      maxWidth: 400,
      width: 250,
    },
  ],
  position: 'left',
  defaultToolPanel: '',
};

export default Logs;
