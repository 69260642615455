import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createLog } from '../../../api/host/internal-logs';
import { dashboard_log } from '../../../utlils/logsMessages';

const SearchParams = (props) => {
  useEffect(() => {
    if (window.history.pushState) {
      const url = new URL(window.location);
      if (props.product.value) url.searchParams.set('addon', props.product.value || '');
      if (props.selectedAccount?.installed?.clientKey)
        url.searchParams.set('account', props.selectedAccount.installed.clientKey || '');
      if (props.selectedUser?.accountId)
        url.searchParams.set('user', props.selectedUser.accountId || '');
      createLog(dashboard_log, 200);
      window.history.pushState({}, '', url);
    }
  }, [props.product.value, props.selectedAccount, props.selectedUser]);

  return null;
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.dashboard.selectedAccount,
    selectedUser: state.dashboard.selectedUser,
    product: state.dashboard.product,
  };
}

export default connect(mapStateToProps)(SearchParams);
