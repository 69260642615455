import React, { useCallback, useEffect, useRef, useState } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';
import { Accordion } from 'react-bootstrap';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import Button from '@atlaskit/button';
import { connect } from 'react-redux';
import { getUserJson, postUserJson } from '../../../api/host/users';
import {
  setSelectedAccount,
  setUpdateJsonEditor,
  setUserJson,
} from '../../../redux/actions/dash-board-actions';
import permissions from '../../../services/Permissions';
import { EmptyState } from '../../../images';
import { account_config_editor_save, user_config_editor_save } from '../../../utlils/logsMessages';
import { createLog } from '../../../api/host/internal-logs';
import JsonModal from './JsonModal';
import { toast } from 'react-toastify';
import Spinner from '@atlaskit/spinner';
import ModalSubmit from '../../../utlils/ModalSubmit';
import getAccounts, { postAccounts } from '../../../api/host/accounts';
import CustomEmptyState from '../../helpers/CustomEmptyState';

const ajv = new Ajv({ allErrors: true, verbose: true });

const JsonEditor = (props) => {
  const [permission, setPermissions] = useState({});
  const [accountJSON, setAccountJSON] = useState(null);
  const [userJSON, setUserJSON] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [cancel, setCancel] = useState(false);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const usersJsonEditorRef = useRef(null);
  const jiraJsonEditorRef = useRef(null);

  useEffect(() => {
    permissions.checkPermissions('dashboard.config-editor').then((permission) => {
      setPermissions(permission);
    });
  }, []);

  useEffect(() => {
    if (props.selectedUser) {
      getUserJson(props.product.value, props.selectedUser.accountId)
        .then((response) => {
          props.dispatchUserJson(response);
        })
        .catch(() => {
          props.dispatchUserJson(null);
        });
    }
  }, [props.product, props.selectedUser, cancel]);

  useEffect(() => {
    if (usersJsonEditorRef.current !== null) {
      usersJsonEditorRef.current.set(props.userJson);
    }
    if (jiraJsonEditorRef.current !== null) {
      jiraJsonEditorRef.current.set(props.jirasJson);
    }
  }, [props.userJson, props.jirasJson, cancel]);

  const userRef = (instance) => {
    if (instance) {
      usersJsonEditorRef.current = instance.jsonEditor;
    } else {
      usersJsonEditorRef.current = null;
    }
  };

  const setUpdate = (value) => {
    props.dispatchUpdateJsonEditor(value);
  };

  const jiraRef = (instance) => {
    if (instance) {
      jiraJsonEditorRef.current = instance.jsonEditor;
    } else {
      jiraJsonEditorRef.current = null;
    }
  };

  const saveAccountJson = () => {
    if (accountJSON) {
      if (JSON.stringify(accountJSON) === JSON.stringify(props.jirasJson)) {
        toast.error('No changes to save');
      } else {
        postAccounts(props.product.value, accountJSON)
          .then(() => {
            toast.success('Account config saved successfully');
            props.dispatchUpdateJsonEditor(true);
            createLog(account_config_editor_save, 200, {
              previous: props.jirasJson,
              current: accountJSON,
            }).catch((e) => {
              console.log(e);
            });
          })
          .catch(() => {
            toast.error('Something gone wrong!');
          });
      }
    } else {
      toast.error('No changes to save');
    }
  };

  const saveUserJson = () => {
    if (userJSON) {
      if (JSON.stringify(userJSON) === JSON.stringify(props.userJson)) {
        toast.error('No changes to save');
      } else {
        postUserJson(props.product.value, props.selectedUser.accountId, userJSON)
          .then(() => {
            createLog(user_config_editor_save, 200, {
              previous: props.userJson,
              current: userJSON,
            }).catch((e) => {
              console.log(e);
            });
            toast.success('User config saved successfully');
            handleClick();
          })
          .catch(() => {
            toast.error('Something gone wrong!');
          });
      }
    } else {
      toast.error('No changes to save');
    }
  };

  const handleClick = () => {
    setUserJSON(props.userJson);
    setAccountJSON(props.jirasJson);
    setCancel(!cancel);
  };

  return (
    <>
      {permission.read ? (
        <Accordion defaultActiveKey="0" className="dashboard-one">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Config Editor</Accordion.Header>
            <Accordion.Body>
              {props.jirasJson ? (
                <Tabs id="editor" onChange={(index) => setActiveTab(index + 1)}>
                  <TabList id="editor">
                    <Tab>Account</Tab>
                    <Tab>User</Tab>
                  </TabList>
                  <TabPanel id="editor">
                    {props.jirasJson &&
                    Object.keys(props.jirasJson).length &&
                    !props.jirasJson.error ? (
                      <div className="jsoneedit">
                        <Editor
                          ref={jiraRef}
                          value={props.jirasJson}
                          ajv={ajv}
                          setUpdateJsonEditor={setUpdate}
                          sortObjectKeys={false}
                          statusBar={false}
                          mainMenuBar={false}
                          onChange={
                            permission.write
                              ? (account) => setAccountJSON(account)
                              : () => {
                                  toast.error("You don't have permission to edit");
                                }
                          }
                          mode="tree"
                        />
                        {permission.write ? (
                          <div className="save-json-button-container">
                            <Button className="save-json-button" onClick={handleClick}>
                              Cancel
                            </Button>
                            <Button
                              className="save-json-button"
                              appearance="primary"
                              onClick={() => {
                                setIsOpen(true);
                              }}>
                              History
                            </Button>
                            <ModalSubmit
                              buttonName={'Save'}
                              submit={saveAccountJson}
                              className={'save-json-button'}
                              text={'Save Account Config?'}
                            />
                          </div>
                        ) : (
                          <> </>
                        )}
                      </div>
                    ) : (
                      <div style={{ width: '100%' }}>
                        <div className="empty-state">
                          {props.jirasJson?.error.length ? (
                            <div className="empty-state"> {EmptyState} </div>
                          ) : (
                            <Spinner size={50} />
                          )}
                        </div>
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel id="user">
                    {props.userJson ? (
                      <dsv className="jsoneedit">
                        <Editor
                          ref={userRef}
                          value={props.userJson}
                          ajv={ajv}
                          sortObjectKeys={false}
                          statusBar={false}
                          mainMenuBar={false}
                          onChange={
                            permission.write
                              ? (user) => {
                                  setUserJSON(user);
                                }
                              : () => {
                                  toast.error("You don't have permission to edit");
                                }
                          }
                          mode="tree"
                        />
                        {permission.write ? (
                          <div className="save-json-button-container">
                            <Button className="save-json-button" onClick={handleClick}>
                              Cancel
                            </Button>
                            <Button
                              className="save-json-button"
                              appearance="primary"
                              onClick={() => {
                                setIsOpen(true);
                              }}>
                              History
                            </Button>
                            <ModalSubmit
                              buttonName={'Save'}
                              submit={saveUserJson}
                              className={'save-json-button'}
                              text={'Save User Config?'}
                            />
                          </div>
                        ) : (
                          <> </>
                        )}
                      </dsv>
                    ) : (
                      <div style={{ width: '100%' }}>
                        <div className="empty-state"> {EmptyState} </div>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              ) : (
                <CustomEmptyState message={'No account selected'} />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
      {isOpen && (
        <>
          <JsonModal
            closeModal={closeModal}
            tabs={activeTab}
            user={props.userJson}
            account={props.jirasJson}
          />
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    userJson: state.dashboard.userJson,
    jirasJson: state.dashboard.jirasJson,
    product: state.dashboard.product,
    selectedUser: state.dashboard.selectedUser,
    setUpdate: state.dashboard.setUpdate,
  };
}

const mapDispatchToProps = {
  dispatchUserJson: setUserJson,
  dispatchUpdateJsonEditor: setUpdateJsonEditor,
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonEditor);
