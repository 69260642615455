import React, { useEffect, useState, useRef } from 'react';
import '../../../App.css';
import { connect } from 'react-redux';
import { getShortUrl } from '../../../utlils';
import { Accordion } from 'react-bootstrap';
import Spinner from '@atlaskit/spinner';
import CustomEmptyState from '../../helpers/CustomEmptyState';
import { getTransactionByLicenceId } from '../../../api/host/marketplace';
import moment from 'moment';
import '../DashBoard.css';
import { ADDONS_MAP } from '../../../utlils/addonsMap';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const MarketplaceTransactions = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(null);
  const gridRef = useRef();

  useEffect(() => {
    setIsLoaded(false);
    if (props.licenseId) {
      getTransactionByLicenceId(props.licenseId).then((response) => {
        const filtered = response.filter(
          (transaction) => ADDONS_MAP[transaction.addonKey] === props.product.value,
        );
        setTransactions(filtered);
        setIsLoaded(true);
      });
    }
  }, [props.licenseId]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  const formatDate = (cell) => {
    return moment(cell.value).format('MMM DD, YYYY');
  };

  const formatPrice = (cell) => {
    return `${cell.value}$`;
  };

  const dateComparator = (date1, date2) => {
    return new Date(date1) - new Date(date2);
  };

  return (
    <Accordion defaultActiveKey="0" className="dashboard" style={{ height: 'auto' }}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          Transactions by {getShortUrl(props.selectedAccount?.installed?.baseUrl)} ({props.product.value}){' '}
        </Accordion.Header>
        <Accordion.Body>
          <div style={{ padding: '10px' }}>
            {!isLoaded || !props.selectedAccount ? (
              <>
                {!props.selectedAccount ? (
                  <CustomEmptyState message={'No account selected'} />
                ) : (
                  <div className="empty-state">
                    <Spinner size={50} />
                  </div>
                )}
              </>
            ) : (
              <>
                {transactions.length ? (
                  <div style={{ width: '100%', height: '300px', paddingBottom: '5px' }}>
                    <div id="myGrid" className="ag-theme-alpine">
                      <AgGridReact
                        enableRangeSelection={true}
                        defaultColDef={{
                          flex: 1,
                          editable: false,
                          sortable: true,
                          filter: true,
                          minWidth: 100,
                        }}
                        ref={gridRef}
                        animateRows={true}
                        rowData={transactions}
                        sideBar={sideBar}
                        autoGroupColumnDef={{
                          headerName: 'Group',
                          minWidth: 250,
                          field: 'model',
                          rowGroupIndex: 1,
                        }}
                        frameworkComponents={{
                          format_date: formatDate,
                          format_price: formatPrice,
                        }}
                        onGridReady={onGridReady}>
                        <AgGridColumn
                          headerName="Sale Date"
                          field="purchaseDetails.saleDate"
                          comparator={dateComparator}
                          cellRenderer="format_date"
                          suppressColumnsToolPanel={true}
                        />
                        <AgGridColumn headerName="Tier" field="purchaseDetails.tier" />
                        <AgGridColumn
                          headerName="Billing Period"
                          field="purchaseDetails.billingPeriod"
                        />
                        <AgGridColumn
                          headerName="Price"
                          field="purchaseDetails.vendorAmount"
                          cellRenderer="format_price"
                        />
                        <AgGridColumn headerName="Sale Type" field="purchaseDetails.saleType" />
                      </AgGridReact>
                    </div>
                  </div>
                ) : (
                  <CustomEmptyState />
                )}
              </>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const sideBar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      minWidth: 180,
      maxWidth: 400,
      width: 250,
    },
  ],
  position: 'left',
  defaultToolPanel: '',
};

const mapStateToProps = (state) => {
  return {
    product: state.dashboard.product,
  };
};

export default connect(mapStateToProps)(MarketplaceTransactions);
