export const logged_in = 'user was logged in';
export const logout = 'user was logged out';
export const dashboard_log = 'Dashboard was successfully loaded';
export const dashboard_log_error = 'Dashboard was not loaded';
export const product_selector = 'Product  was successfully selected by user';
export const account_selector = 'Account  was successfully selected by user';
export const user_selector = 'User  was successfully selected by user';
export const installed_addons = 'Installed addons were successfully loaded';
export const installed_addons_action = 'Installed addons were successfully selected by user';
export const account_config_editor_save = 'account config was successfully saved by user';
export const user_config_editor_save = 'user config was successfully saved by user';
export const switched_tabs = 'user switched tabs';
export const applied_query = 'applied query was successfully';
export const searched_by_query = 'user searched by query';
export const created_query = 'user was successfully created query';
export const renamed_query = 'user renamed query';
export const deleted_query = 'user was successfully deleted query';
export const created_notification = 'user was successfully created notification';
export const edited_notification = 'user was successfully edited notification';
export const deleted_notification = 'user was successfully deleted notification';
export const viewed_notification_message = 'user was successfully viewed notification message';
export const changed_role = 'user role was successfully changed';
export const viewed_logs_param = 'user was successfully viewed logs param';
export const user_deleted = 'user was successfully deleted';
