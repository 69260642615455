// eslint-disable
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import {
  SET_PRODUCT,
  SET_SELECTED_USERS,
  SET_SELECTED_ACCOUNTS,
} from '../types/notifications-types';

const initialState = {
  product: [],
  selectedUsers: [],
  selectedAccounts: [],
  newAccounts: [],
  editMode: [],
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT: {
      return {
        ...state,
        product: action.payload,
        selectedAccounts: [],
        selectedUsers: [],
        newAccounts: [],
      };
    }
    case SET_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    }
    case SET_SELECTED_ACCOUNTS: {
      return {
        ...state,
        selectedAccounts: action.payload,
      };
    }
    default:
      return state;
  }
};
