import { AxiosResponse } from 'axios';
import axios from '../axiosClient';
import { AccountType } from '../../types/AccountType';

export default function getAccounts(product: string, account: string | string[], query?: string) {
  return axios
    .post('/api/user/jira?', {
      product,
      account,
      query,
    })
    .then((response: AxiosResponse<AccountType[]>) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function getAccountById(product: string, account: string) {
  return axios
    .get(`/api/user/jira/json?product=${product}&account=${account}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export function postAccounts(product: string, jira: string) {
  return axios
    .post('/api/user/jira', {
      product,
      jira,
    })
    .catch((error) => {
      console.log(error);
    });
}
