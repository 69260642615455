import React, { useState, useRef } from 'react';
import '../Notification.css';
import ReactQuill from 'react-quill';
import EditorToolbar, { formats, modules } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({
  createText,
  text,
  maxLength,
}: {
  createText: (text: string) => void;
  text?: string;
  maxLength: number;
}) => {
  const [message, setMessage] = useState(text || '');
  const quillRef = useRef<ReactQuill | null>(null);

  const reactQuillHandleChange = () => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const plainText = editor.getText();
      if (plainText.length > maxLength) {
        editor.deleteText(maxLength, plainText.length);
      }
      setMessage(editor.root.innerHTML);
      createText(editor.root.innerHTML);
    }
  };

  return (
    <div className="text-editor" style={{ height: '100%' }}>
      <p>
        Message<span className="required">*</span>
      </p>
      <EditorToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={message}
        onChange={reactQuillHandleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default TextEditor;
