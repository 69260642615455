import { AxiosResponse } from 'axios';
import axios from '../api/axiosClient';
import { UserType } from '../types/UserType';
import { PermissionsCheckResponse } from '../types/Responses/PermissionCheckResponse';
import { SectionsResponse } from '../types/Responses/SectionResponse';

class Permissions {
  public user?: UserType;

  setUser(user: UserType) {
    this.user = user;
    return this;
  }

  checkPermissions(component: string) {
    if (!this.user) {
      throw new Error('no user provided');
    }
    return axios
      .get(`/api/permissions/check?userId=${this.user.id}&component=${component}`)
      .then((response: AxiosResponse<PermissionsCheckResponse>) => {
        return response.data;
      });
  }

  getAvailableSections() {
    if (!this.user) {
      throw new Error('no user provided');
    }
    return axios
      .get(`/api/permissions/sections?userId=${this.user.id}`)
      .then((response: AxiosResponse<SectionsResponse>) => {
        return response.data;
      });
  }
}

const permissions = new Permissions();
export default permissions;
