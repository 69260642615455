import { InMemoryCache } from '@apollo/client';
import { globalStateVar } from '../variabels/variabels';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        globalState: {
          read() {
            return globalStateVar();
          },
        },
      },
    },
  },
});
