import axios from '../axiosClient';

export default function checkAddonUsage(selectedAccount: string) {
  return axios
    .get(`/api/addons/usage?key=${selectedAccount}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error) {
        throw new Error("Can't find Installed Addons'");
      }
    });
}

export function getAddons() {
  return axios
    .get('/api/addons')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error) {
        throw new Error("Can't load Addons'");
      }
    });
}
