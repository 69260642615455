import React from 'react';
import ReactQuill from 'react-quill';
import '../Notification.css';

const HtmlViewer = ({ template }) => {
  return (
    <>
      {template ? (
        <ReactQuill value={template} modules={{ toolbar: false }} readOnly={true} />
      ) : (
        <> </>
      )}
    </>
  );
};

export default HtmlViewer;
