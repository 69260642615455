import { AxiosResponse } from 'axios';
import axios from '../axiosClient';
import { JiraUserType } from '../../types/JiraUserType';

export default function getUser(
  selectedAccount?: string,
  product?: string,
  accountId?: string,
  query?: string,
) {
  return axios
    .post('/api/jira?', {
      selectedAccount,
      product,
      accountId,
      query,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getFullUser(selectedAccount: string | string[], product: string, query: string) {
  return axios
    .post('/api/jira/user?', {
      selectedAccount,
      product,
      query
    })
    .then((response: AxiosResponse<JiraUserType[]>) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export function getUserFullInfo(clientKey: string, url: string, product: string) {
  return axios
    .get(`/api/jira/user/by/key?&key=${clientKey}&url=${url}&product=${product}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

export const getTickets = (account: string) => {
  return axios.get(`/api/jira/tickets/${account}`);
};

export function getUserJson(product: string, clientKey: string) {
  return axios.get(`/api/user?product=${product}&key=${clientKey}`).then((response) => {
    return response.data;
  });
}

export function postUserJson(product: string, clientKey: string, user: string) {
  return axios
    .post(`/api/user?&key=${clientKey}`, {
      user,
      product,
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getUsers() {
  return axios('/api/manage/user')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function changeUser(name: string, value: string, email: string) {
  return axios('/api/manage/user/change', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      current: JSON.stringify({ [name]: value }),
      previous: JSON.stringify({ email }),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export const removeUser = (userId: string) => {
  return axios(`/api/manage/user/remove/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
