import { SET_USER, SET_COMPONENT } from '../types/main-types';
import { UserType } from '../../types/UserType';

type InitialStateType = {
  user: UserType | null;
  component: string;
};

const initialState: InitialStateType = {
  user: null,
  component: window.location.pathname,
};

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, user: action.payload };
    }
    case SET_COMPONENT: {
      return { ...state, component: action.payload };
    }
    default:
      return state;
  }
};
