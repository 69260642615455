import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_NOTIFICATION_API_URL}/graphql` });

const authLink = setContext(() => {
  try {
    const jwt = process.env.REACT_APP_JWT_KEY;
    const addonKey = 'TIS';

    return {
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : null,
        addon_key: addonKey,
      },
    };
  } catch (error) {
    console.log(error);
  }
});

export const link = authLink.concat(httpLink);
