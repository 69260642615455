import React, { useEffect, useState } from 'react';
import '../DashBoard.css';
import { connect } from 'react-redux';
import { setProduct } from '../../../redux/actions/dash-board-actions';
import checkAddonUsage from '../../../api/host/addons';
import { createLog } from '../../../api/host/internal-logs';
import { installed_addons_action } from '../../../utlils/logsMessages';

const InstalledAddons = (props) => {
  const [checkedUser, setCheckedUser] = useState([]);

  useEffect(() => {
    if (props.selectedAccount?.installed?.clientKey) {
      checkAddonUsage(props.selectedAccount.installed.clientKey).then((response) => {
        if (response.length !== 1) {
          response = response.filter((product) => product !== props.product.value);
          setCheckedUser(response);
        }
      });
    }
  }, [props.selectedAccount]);

  const handleSetConfig = (product) => {
    props.dispatchProduct(product);
    createLog(installed_addons_action, 200);
  };

  return (
    <>
      {props.selectedAccount?.installed?.clientKey && checkedUser.length ? (
        <span className="addons-button">
          <span className="we-also-text"> We also found this user in other products: </span>
          {checkedUser.map((product, i) => {
            return product ? (
              <button
                className="installed-addons-button"
                key={i}
                onClick={() => handleSetConfig({ value: product, label: product })}>
                {product}
              </button>
            ) : (
              <> </>
            );
          })}
        </span>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    product: state.dashboard.product,
    selectedUser: state.dashboard.selectedUser,
  };
};
const mapDispatchToProps = {
  dispatchProduct: setProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstalledAddons);
