import axios from '../axiosClient';

export function getLogsByAccount(atlassianName: string, product: string) {
  return axios
    .get(`/api/data/user/logs?clientKey=${atlassianName}&product=${product}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
}

export async function getLogsByQuery(cursor: string, query: string) {
  return await axios
    .get(`/api/data?cursor=${cursor}&query=${query || ''}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
