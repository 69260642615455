import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { connect } from 'react-redux';
import { getShortUrl } from '../../../utlils';
import { Accordion, Card, Row, Col, Badge, Button } from 'react-bootstrap';
import Spinner from '@atlaskit/spinner';
import CustomEmptyState from '../../helpers/CustomEmptyState';
import { getTickets } from '../../../api/host/users';
import { LinkedIssue } from '../../../images';
import '../DashBoard.css';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const jiraField = {
  'Cost Center': 'customfield_10098',
};

const JiraTickets = (props) => {
  const [tickets, setTickets] = useState([]);
  const [isLoaded, setIsLoaded] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('All');
  const [showLinked, setShowLinked] = useState([]);

  const url = new URL(document.URL).searchParams;
  let productUrl = url.get('addon');

  useEffect(() => {
    setIsLoaded(false);
    if (props.selectedAccount) {
      getTickets(getShortUrl(props.selectedAccount.installed?.baseUrl)).then((response) => {
        setTickets(response.data.issues);
        setTabs(getTabs(response.data.issues));
        setIsLoaded(true);
      });
    }
  }, [props.selectedAccount]);

  const getTabs = (issues) => {
    const tabs = issues.reduce(
      (acc, issue) => {
        const addon = issue.fields[jiraField['Cost Center']]?.value || 'Empty';
        if (acc.includes(addon)) {
          return acc;
        } else {
          return [...acc, addon];
        }
      },
      ['All'],
    );
    if (tabs.includes(productUrl)) {
      setSelectedTab(productUrl);
    }
    return tabs;
  };

  const filterIssuesByAddon = (issues) => {
    const sortByDate = (a, b) => new Date(b.fields.created) - new Date(a.fields.created);
    switch (selectedTab) {
      case 'All':
        return issues.sort(sortByDate);
      case 'Empty':
        return issues.filter((issue) => !issue.fields[jiraField['Cost Center']]).sort(sortByDate);
      default:
        return issues
          .filter((issue) => issue.fields[jiraField['Cost Center']]?.value === selectedTab)
          .sort(sortByDate);
    }
  };

  const getStatus = (status) => {
    const statusKey = status.statusCategory.key;
    switch (statusKey) {
      case 'indeterminate': {
        return <Badge bg="primary">{status.name}</Badge>;
      }
      case 'new': {
        return <Badge bg="secondary">{status.name}</Badge>;
      }
      case 'done': {
        return <Badge bg="success">{status.name}</Badge>;
      }
    }
  };

  const linkedIssuesControler = (key) => {
    if (showLinked.includes(key)) {
      setShowLinked(showLinked.filter((link) => link !== key));
    } else {
      setShowLinked([...showLinked, key]);
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="dashboard" style={{ height: 'auto' }}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {' '}
          Tickets created by {getShortUrl(props.selectedAccount?.installed?.baseUrl)}{' '}
        </Accordion.Header>
        <Accordion.Body>
          <div style={{ padding: '10px' }}>
            {!isLoaded || !props.selectedAccount ? (
              <>
                {!props.selectedAccount ? (
                  <CustomEmptyState message={'No account selected'} />
                ) : (
                  <div className="empty-state">
                    <Spinner size={50} />
                  </div>
                )}
              </>
            ) : (
              <>
                {tickets.length ? (
                  <>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={selectedTab}
                      onSelect={(k) => setSelectedTab(k)}
                      className="mb-3">
                      {tabs.map((addon) => (
                        <Tab key={addon} eventKey={addon} title={addon}>
                          {filterIssuesByAddon(tickets).map((ticket) => {
                            return (
                              <>
                                <Card key={ticket.key} style={{ marginTop: '10px' }}>
                                  <Card.Body>
                                    <Row>
                                      <Col sm={2}>
                                        <a
                                          href={`https://saasjet.atlassian.net/browse/${ticket.key}`}
                                          target="_blank"
                                          rel="noreferrer">
                                          {ticket.key}
                                        </a>
                                        {ticket.fields.issuelinks.length ? (
                                          <button
                                            title={`Show linked issues for ${ticket.key}`}
                                            className="linked-issues-toggler"
                                            onClick={() => linkedIssuesControler(ticket.key)}>
                                            {LinkedIssue}
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                      <Col sm={7}>
                                        <b>
                                          {selectedTab === 'All'
                                            ? `(${
                                                ticket.fields[jiraField['Cost Center']]?.value ||
                                                'Empty'
                                              }) `
                                            : ''}
                                        </b>
                                        {ticket.fields.summary.length > 35
                                          ? `${ticket.fields.summary.slice(0, 35)}...`
                                          : ticket.fields.summary}
                                      </Col>
                                      <Col sm={3}>{getStatus(ticket.fields.status)}</Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                                {showLinked.includes(ticket.key) &&
                                ticket.fields.issuelinks.length ? (
                                  ticket.fields.issuelinks.map((linkedIssue) => {
                                    const linked =
                                      linkedIssue.inwardIssue || linkedIssue.outwardIssue;
                                    return (
                                      <Card
                                        className="linked-issue-card"
                                        key={linked.key}
                                        style={{
                                          marginTop: '10px',
                                          width: '90%',
                                          marginLeft: '10%',
                                          backgroundColor: '#f9f9f9',
                                        }}>
                                        <Card.Body className="linked-issue-card-body">
                                          <Row>
                                            <Col sm={3}>
                                              <a
                                                href={`https://saasjet.atlassian.net/browse/${linked.key}`}
                                                target="_blank"
                                                rel="noreferrer">
                                                {linked.key}
                                              </a>
                                            </Col>
                                            <Col sm={6}>
                                              {linked.fields.summary.length > 25
                                                ? `${linked.fields.summary.slice(0, 25)}...`
                                                : linked.fields.summary}
                                            </Col>
                                            <Col sm={3}>{getStatus(linked.fields.status)}</Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </Tab>
                      ))}
                    </Tabs>
                  </>
                ) : (
                  <CustomEmptyState />
                )}
              </>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

function mapStateToProps(state) {
  return {
    product: state.dashboard.product,
  };
}

export default connect(mapStateToProps)(JiraTickets);
