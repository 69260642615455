import React, { useEffect, useState } from 'react';
import JsonEditor from './widgets/JsonEditor';
import InstalledAddons from './helpers/InstalledAddons';
import HubSpot from './widgets/HubSpot';
import Chart from './widgets/Chart';
import UserPanel from './widgets/UserPanel';
import SearchParams from './helpers/SearchParams';
import {
  setProduct,
  setSelectedAccount,
  setSelectedUser,
  setSelectedJiraJSON,
} from '../../redux/actions/dash-board-actions';
import './DashBoard.css';
import { connect } from 'react-redux';
import { getShortUrl } from '../../utlils';
import { account_selector, product_selector, user_selector } from '../../utlils/logsMessages';
import { createLog } from '../../api/host/internal-logs';
import permissions from '../../services/Permissions';
import AccountSelectorSingle from '../UserSelectorPanel/Selectors/AccountSelectorSingle';
import ProductSelectorSingle from '../UserSelectorPanel/Selectors/ProductSelectorSingle';
import UserSelectSingle from '../UserSelectorPanel/Selectors/UserSelectSingle';
import JiraTickets from './widgets/JiraTickets';
import MarketplaceTransactions from './widgets/Marketplace';

const Dashboard = (props) => {
  const [permission, setPermissions] = useState({});
  const [licenseId, setLicenseId] = useState(undefined);

  useEffect(() => {
    permissions.checkPermissions('notifications').then((permission) => {
      setPermissions(permission);
    });
  }, []);

  const setUsers = (value) => {
    props.dispatchSelectedUser(value);
  };

  const setJiraJSON = (value) => {
    props.dispatchJiraJSON(value);
  };

  const AccountSelectorOnchange = (account) => {
    props.dispatchSelectedAccount(account);
    createLog(account_selector, 200);
  };

  const UserSelectorOnChange = (user) => {
    createLog(user_selector, 200);
    props.dispatchSelectedUser(user);
  };

  const setProduct = (value) => {
    props.dispatchProduct(value);
    createLog(product_selector, 200);
  };

  const AccountSelectorOptions = (accounts) => {
    return accounts.map((account) => {
      return {
        value: account.installed.clientKey,
        label: getShortUrl(account.installed?.baseUrl),
        tooltipDescription: account.installed.clientKey,
        tooltipPosition: 'left',
      };
    });
  };

  const UserSelectorOptions = (users) => {
    return users.map((user) => {
      return {
        value: user.accountId,
        label:
          user.displayName.length < 30
            ? user.displayName
            : user.displayName.substring(0, 20) + '...',
        tooltipDescription: user.displayName.length < 30 ? undefined : user.displayName,
        tooltipPosition: 'left',
      };
    });
  };

  return (
    <div className="inner-dashboard">
      <div className="dashboard-container">
        <SearchParams />
        <div className="dropdown-container">
          <p className="selected-by-text"> Selected By: </p>
          <div className="product-selector">
            <ProductSelectorSingle
              selectedValues={{
                user: props.selectedUser,
                account: props.selectedAccount,
                product: props.product,
              }}
              setSelectedProduct={setProduct}
              isDisabled={false}
            />
          </div>
          <div className="account-selector">
            <AccountSelectorSingle
              selectedValues={{
                user: props.selectedUser,
                account: props.selectedAccount,
                product: props.product,
              }}
              setStoreAccount={AccountSelectorOnchange}
              setStoreJiraJSON={setJiraJSON}
              selectedAccount={props.selectedAccount}
              options={AccountSelectorOptions}
            />
          </div>
          <div className="user-selector">
            <UserSelectSingle
              selectedValues={{
                user: props.selectedUser,
                account: props.selectedAccount,
                product: props.product,
              }}
              setSelectedUser={setUsers}
              setStoreUser={UserSelectorOnChange}
              options={UserSelectorOptions}
            />
          </div>
        </div>
        {props.selectedAccount && <InstalledAddons selectedAccount={props.selectedAccount} />}
        <UserPanel selectedAccount={props.selectedAccount} />
        {permission.write ? (
          <HubSpot selectedAccount={props.selectedAccount} setLicenseId={setLicenseId} />
        ) : (
          <></>
        )}
        {permission.write ? (
          <MarketplaceTransactions selectedAccount={props.selectedAccount} licenseId={licenseId} />
        ) : (
          <></>
        )}
        <JiraTickets selectedAccount={props.selectedAccount} />
        <JsonEditor />
        <Chart selectedAccount={props.selectedAccount} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.dashboard.selectedAccount,
    selectedUser: state.dashboard.selectedUser,
    product: state.dashboard.product,
    setQueryUser: state.dashboard.setQueryUser,
  };
}

const mapDispatchToProps = {
  dispatchProduct: setProduct,
  dispatchSelectedUser: setSelectedUser,
  dispatchSelectedAccount: setSelectedAccount,
  dispatchJiraJSON: setSelectedJiraJSON,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
