import React from 'react';
import { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const LastViewTable = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleWrapper = (cell) => {
    return cell.value?.replace(/^https?:\/\//, '')?.replace(/\..*/, '');
  };

  return (
    <>
      <div style={{ width: '100%', height: '400px' }}>
        <div
          id="myGrid"
          style={{
            height: '400px',
            width: '100%',
          }}
          className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              filter: true,
            }}
            frameworkComponents={{
              wrapper: handleWrapper,
            }}
            rowData={props.data}
            onGridReady={onGridReady}></AgGridReact>
        </div>
      </div>
    </>
  );
};

const columnDefs = [
  {
    headerName: 'Date',
    field: 'date',
    filter: 'true',
  },
  {
    headerName: 'Account',
    field: 'jiraText',
    cellRenderer: 'wrapper',
  },
  {
    headerName: 'User',
    field: 'userAccountText',
  },
];

export default LastViewTable;
