import React, { useEffect, useState } from 'react';
import '../DashBoard.css';
import { connect } from 'react-redux';
import getDeals from '../../../api/host/hubspot';
import { getShortUrl } from '../../../utlils';
import { Accordion, Form } from 'react-bootstrap';
import moment from 'moment';
import permissions from '../../../services/Permissions';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { EmptyState } from '../../../images';
import Spinner from '@atlaskit/spinner';
import styled from '@emotion/styled';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import CustomEmptyState from '../../helpers/CustomEmptyState';

const HubSpot = (props) => {
  const [deals, setDeals] = useState([]);
  const [permission, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDeals([]);
    setIsLoading(true);
    permissions.checkPermissions('dashboard.hubspot').then((permission) => {
      setPermissions(permission);
    });
    if (props.selectedAccount?.installed?.baseUrl) {
      const account = getShortUrl(props.selectedAccount);
      getDeals(props.product.value, account).then((response) => {
        if (response && response.length === 0) {
          setPermissions(false);
        } else if (response) {
          props.setLicenseId(response[0].properties.license_id);
          setDeals(response);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.product.value, props.selectedAccount]);

  const InlineDialog = styled(TooltipPrimitive)`
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #333;
    max-width: 235px;
    padding: 8px 12px;
  `;

  const HubspotStageMap = deals
    .map((deal) => {
      return {
        appointmentscheduled:
          deal.properties.dealstage === 'appointmentscheduled' ? 'Start trial' : undefined,
        presentationscheduled:
          deal.properties.dealstage === 'presentationscheduled' ? 'Trial cancelled' : undefined,
        decisionmakerboughtin:
          deal.properties.dealstage === 'decisionmakerboughtin'
            ? 'Subscribtion cancelled'
            : undefined,
        qualifiedtobuy: deal.properties.dealstage === 'qualifiedtobuy' ? 'Subscribed' : undefined,
      };
    })
    .map(
      (e) =>
        e?.appointmentscheduled ||
        e?.presentationscheduled ||
        e?.decisionmakerboughtin ||
        e?.qualifiedtobuy,
    )[0];

  return (
    <>
      <div>
        <Form>
          <Accordion defaultActiveKey="0" className="dashboard">
            <Accordion.Item eventKey="0">
              <Accordion.Header>HubSpot </Accordion.Header>
              <Accordion.Body className={props.selectedAccount ? 'card' : ''}>
                {isLoading ? (
                  <div style={{ width: '100%' }}>
                    <div className="empty-state">
                      <Spinner size={50} />
                    </div>
                  </div>
                ) : (
                  <>
                    {props.selectedAccount && deals.length !== 0 && permission === true ? (
                      <>
                        {deals.length !== 0 ? (
                          <Tabs>
                            <TabList>
                              {deals.map((deal) => (
                                <Tooltip
                                  key={deal.id}
                                  content={
                                    deal.properties.dealname
                                      ? deal.properties.dealname.match(/(?<=\().+?(?=\))/g)[0]
                                      : ''
                                  }>
                                  <Tab>
                                    {' '}
                                    {deal.properties.dealname
                                      ? deal.properties.dealname.match(/(?<=\().+?(?=\))/g)[0]
                                      : ''}{' '}
                                  </Tab>
                                </Tooltip>
                              ))}
                            </TabList>
                            {deals.map((deal) => (
                              <TabPanel key={deal.id}>
                                <div className="hubspot-container">
                                  <div className="hubspot-left-container">
                                    <div className="text-container hubspot-value-container">
                                      <p> Amount: </p>
                                      <p> Deal type: </p>
                                      <p> Amount of users: </p>
                                      <p> Churn Risk: </p>
                                      <p> Used last activity: </p>
                                      <p> Deal stage: </p>
                                    </div>
                                    <div className="text-message-container hubspot-value-container">
                                      <p>
                                        {' '}
                                        {deal.properties.amount
                                          ? '$' + deal.properties.amount
                                          : 'None'}
                                      </p>
                                      <p>
                                        {' '}
                                        {deal.properties.dealtype
                                          ? deal.properties.dealtype
                                          : 'None'}
                                      </p>
                                      <p>
                                        {' '}
                                        {deal.properties.amount_of_users
                                          ? deal.properties.amount_of_users
                                          : 'Unknown'}
                                      </p>
                                      <p>
                                        {' '}
                                        {deal.properties.churn_risk
                                          ? deal.properties.churn_risk
                                          : 'None'}
                                      </p>
                                      <p>
                                        {' '}
                                        {deal.properties.usedlastactivity
                                          ? moment(deal.properties.usedlastactivity).format(
                                              'MMM DD, YYYY HH:mm:ss',
                                            )
                                          : 'None'}
                                      </p>
                                      <p> {HubspotStageMap ? HubspotStageMap : 'None'} </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="hubspot-right-container" key={deal.id}>
                                  <div className="text-container hubspot-value-container">
                                    <p> Created At: </p>
                                    <p> Create date: </p>
                                    <p> Close date: </p>
                                    <p> Cancellation date: </p>
                                    <p>Updated At: </p>
                                    <p>Competitor: </p>
                                  </div>
                                  <div className="text-message-container hubspot-value-container">
                                    <p>
                                      {' '}
                                      {deal.createdAt
                                        ? moment(deal.createdAt).format('MMM DD, YYYY HH:mm:ss')
                                        : 'None'}
                                    </p>
                                    <p>
                                      {' '}
                                      {deal.properties.createdate
                                        ? moment(deal.properties.createdate).format(
                                            'MMM DD, YYYY HH:mm:ss',
                                          )
                                        : 'None'}
                                    </p>
                                    <p>
                                      {' '}
                                      {deal.properties.closedate
                                        ? moment(deal.properties.closedate).format(
                                            'MMM DD, YYYY HH:mm:ss',
                                          )
                                        : 'None'}
                                    </p>
                                    <p>
                                      {' '}
                                      {deal.properties.cancellation_date
                                        ? moment(deal.properties.cancellation_date).format(
                                            'MMM DD, YYYY HH:mm:ss',
                                          )
                                        : 'None'}
                                    </p>
                                    <p>
                                      {' '}
                                      {deal.updatedAt
                                        ? moment(deal.updatedAt).format('MMM DD, YYYY HH:mm:ss')
                                        : 'None'}
                                    </p>
                                    {deal.properties.tis_competitor ? (
                                      <Tooltip
                                        component={InlineDialog}
                                        content={deal.properties.tis_competitor.replace(
                                          /;/gi,
                                          '\n',
                                        )}>
                                        <p>
                                          {' '}
                                          {deal.properties.tis_competitor.length < 30
                                            ? deal.properties.tis_competitor
                                            : deal.properties.tis_competitor.substring(0, 20) +
                                              '...'}{' '}
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      'None'
                                    )}
                                  </div>
                                </div>
                              </TabPanel>
                            ))}
                          </Tabs>
                        ) : (
                          <div style={{ width: '100%' }}>
                            <div className="empty-state">{EmptyState}</div>
                          </div>
                        )}
                      </>
                    ) : (
                      <CustomEmptyState message="No account selected" />
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    accounts: state.dashboard.accounts,
    product: state.dashboard.product,
    jirasJson: state.dashboard.jirasJson,
  };
}

export default connect(mapStateToProps)(HubSpot);
