import React, { useEffect, useState } from 'react';
import Select, { components } from '@atlaskit/select';
import { selectorStyle } from '../selectorStyles';
import Tooltip from '@atlaskit/tooltip';
import { setQueryUser, setSelectedUser } from '../../../redux/actions/dash-board-actions';
import { connect } from 'react-redux';
import getUser from '../../../api/host/users';

const UserSelector = (props) => {
  const [user, setUser] = useState({
    label: 'None',
    value: null,
  });
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const url = new URL(document.URL).searchParams;
  let userUrl = url.get('user') || '';

  const debounce = (cb, delay = 1000) => {
    return (...args) => {
      clearTimeout(debounceTimeout);
      setIsLoading(true);
      if (args[1].action === 'menu-close') {
        setIsLoading(false);
      }
      setDebounceTimeout(
        setTimeout(() => {
          cb(...args);
        }, delay),
      );
    };
  };
  const setUsersList = () => {
    if (
      props.selectedValues?.account?.installed?.clientKey &&
      props.selectedValues?.product?.value
    ) {
      getUser(
        props.selectedValues?.account?.installed?.clientKey,
        props.product.value,
        [],
        searchQuery,
      )
        .then((response) => {
          if (response?.length) {
            if (props.selectedValues.user) {
              let filter = response.filter((e) => e.accountId !== user.value);
              setUsers([props.selectedValues.user, ...filter]);
            } else {
              setUsers([...response]);
            }
          } else {
            setUser({
              label: 'None',
              value: null,
            });
            props.setStoreUser(null);
            setUsers([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setUsers([]);
          setIsLoading(false);
        });
    } else {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const isSelectedValidForAccount = (userId = userUrl) => {
    if (
      props.selectedValues?.account?.installed?.clientKey &&
      props.selectedValues?.product?.value &&
      userId
    ) {
      getUser(
        props.selectedValues?.account?.installed?.clientKey,
        props.product.value,
        userId,
        userId,
      )
        .then((response) => {
          if (!response?.length) {
            setUser({
              label: 'None',
              value: null,
            });
            props.setStoreUser(null);
          } else {
            setUser({
              value: response[0].accountId,
              label: response[0].displayName,
            });
            props.setStoreUser(response[0]);
          }
        })
        .catch((error) => {
          props.setStoreUser(null);
          setUser({
            label: 'None',
            value: null,
          });
          console.log(error);
        });
    } else {
      setUser({
        label: 'None',
        value: null,
      });
    }
  };

  const userOnChange = (account) => {
    isSelectedValidForAccount(account);
  };

  useEffect(() => {
    setUsersList();
    isSelectedValidForAccount();
  }, []);

  useEffect(() => {
    isSelectedValidForAccount();
  }, [props.selectedValues.account]);

  useEffect(() => {
    setUsersList();
  }, [props.selectedValues.account, searchQuery]);

  return (
    <Select
      isLoading={isLoading}
      components={{ Option }}
      className="select-container"
      value={user}
      styles={selectorStyle}
      inputId="grouped-options-example"
      onInputChange={debounce((inputValue) => setSearchQuery(inputValue))}
      onChange={(user) => userOnChange(user.value)}
      options={props.options(users).sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

const Option = (props) => {
  const { data } = props;
  const { tooltipDescription, tooltipPosition } = data;
  return (
    <Tooltip content={tooltipDescription} position={tooltipPosition}>
      <components.Option {...props} />
    </Tooltip>
  );
};

function mapStateToProps(state) {
  return {
    product: state.dashboard.product,
    setQueryUser: state.dashboard.setQueryUser,
  };
}

const mapDispatchToProps = {
  dispatchQueryUser: setQueryUser,
  dispatchSelectedUser: setSelectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelector);
