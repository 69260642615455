import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine-dark.css';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import '../Pivot.css';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import moment from 'moment';
import Loading from '../../../utlils/Loading';

const PivotTable = ({ data }) => {
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    if (gridColumnApi) {
      fullPivot();
    }
  }, [gridColumnApi]);

  const gridOptions = {
    aggFuncs: {
      result: (params) => {
        let sum = 0;
        params.values.forEach((value) => (sum += value));
        return sum;
      },
    },
  };

  const fullPivot = () => {
    gridColumnApi.setPivotMode(true);
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'product',
          rowGroup: true,
        },
        {
          colId: 'account',
          rowGroup: true,
        },
        {
          colId: 'useraccount',
          rowGroup: true,
        },
        {
          colId: 'message',
          rowGroup: true,
        },
        {
          colId: 'date',
          pivot: true,
        },
        {
          groupId: 'Date',
          pivot: true,
          openByDefault: true,
        },
        {
          colId: 'year',
          pivot: true,
        },
        {
          colId: 'month',
          pivot: true,
        },
        {
          colId: 'day',
          pivot: true,
        },
      ],
      defaultState: {
        pivot: false,
        rowGroup: false,
      },
    });
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: false,
          suppressValues: true,
          suppressPivots: false,
          suppressPivotMode: false,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    position: 'left',
    defaultToolPanel: '',
  };

  const groupDisplayType = 'singleColumn';
  return (
    <>
      <div className="pivot-table">
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            modules={[
              ClientSideRowModelModule,
              RowGroupingModule,
              AllCommunityModules,
              FiltersToolPanelModule,
              ColumnsToolPanelModule,
              SetFilterModule,
            ]}
            suppressAggFuncInHeader={true}
            animateRows={true}
            suppressExpandablePivotGroups={true}
            groupDisplayType={groupDisplayType}
            autoGroupColumnDef={{
              headerName: 'Product',
              initialPinned: 'left',
              minWidth: 345,
            }}
            defaultColDef={{
              flex: 1,
              minWidth: 430,
              sortable: true,
              resizable: true,
              filter: true,
            }}
            sideBar={sideBar}
            frameworkComponents={{
              resultRender: Result,
            }}
            rowData={getLogs(data)}
            onGridReady={(params) => {
              setGridColumnApi(params.columnApi);
            }}
            gridOptions={gridOptions}>
            <AgGridColumn field="product" rowGroup={true} enableRowGroup={false} />
            <AgGridColumn field="account" rowGroup={true} enableRowGroup={false} />
            <AgGridColumn field="useraccount" rowGroup={true} enableRowGroup={false} />
            <AgGridColumn field="message" rowGroup={true} enableRowGroup={false} />
            <AgGridColumn field="year" groupId="year" width={300} headerName="Date">
              {/*<AgGridColumn field="month" groupId="month" width={300}/>*/}
              {/*<AgGridColumn field="day" groupId="day" width={300}/>*/}
            </AgGridColumn>
            <AgGridColumn
              field="total"
              cellRenderer="resultRender"
              aggFunc="result"
              wrapText={true}
              autoHeight={true}
              autoWidth={true}
              width={300}
            />
          </AgGridReact>
        </div>
      </div>
    </>
  );
};

const Result = (cell) => {
  const logs = cell.value ? cell.value.match(/{.+?}/g) : [];
  return (
    <React.Fragment>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{logs.length}</Tooltip>}>
        <div className="pivot-dots-container">
          {logs.map((el, i) => {
            const parsedElement = JSON.parse(el);
            let className;
            if (parsedElement.ok) {
              className = 'green-point';
            } else if (parsedElement.warn) {
              className = 'yellow-point';
            } else if (parsedElement.error) {
              className = 'red-point';
            }
            return <span key={i} className={className} />;
          })}
        </div>
      </OverlayTrigger>
    </React.Fragment>
  );

  function getLogs(logs) {
    return logs.map((e) => {
      const log = e.attributes.attributes;
      let date = new Date(log.date);
      const fullDate = moment(new Date(e.timestamp ? e.timestamp : e.attributes.timestamp));
      if (!moment(date).isValid()) {
        date = moment(log.date, 'YYYY-MM-DD HH-mm-ss');
      }
      if (!moment(date).isValid()) {
        date = moment(log.timestamp, 'YYYY-MM-DD HH:mm:ss:SSS');
      }
      try {
        return {
          Date: fullDate,
          year: moment(date).format('YYYY/MM/DD'),
          // month: moment(date).format("MM"),
          // day: moment(date).format("DD"),
          fullDate: log.date,
          product: log.service,
          account: log['atlassian_name'],
          useraccount: log['user_id'],
          clientKey: log.clientKey,
          message: log['log_msg'],
          total: JSON.stringify({
            ok: log.level ? +log.level.includes('info') : +log.status.includes('info'),
            warn: log.level ? +log.level.includes('warn') : +log.status.includes('warn'),
            error: log.level ? +log.level.includes('error') : +log.status.includes('error'),
            debug: log.level ? +log.level.includes('debug') : +log.status.includes('debug'),
          }),
        };
      } catch (error) {
        console.log(error);
      }
    });
  }
};

export default PivotTable;
